import {createContext, useEffect, useState} from "react";
import {financeData, cardPayNowReq, updateInvoiceReq} from "./_request";
import {useLocation} from "react-router-dom";
import {customAlert} from "../../general/helpers";
export const InvoiceContext = createContext();
export const InvoiceContextProvider = ({children}) => {
    const {pathname} = useLocation();

    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [receiptData, setReceiptData] = useState([]);
    const [unpaidInvoice, setUnpaidInvoice] = useState([]);
    const [accountStatements, setAccountStatements] = useState([]);
    const [viewAccountStatements, setViewAccountStatements] = useState([]);
    const getFinanceData = async () => {
        setLoading(true);
        const response = await financeData();
        if(response.status.toLowerCase() === "success"){
            setInvoiceData(response.invoices);
            setReceiptData(response.receipts);
            setUnpaidInvoice(response.unpaid_invoices);
            setAccountStatements(response);
        }
        setLoading(false);
    }
    const cardPayNow = async (data, dispatchBy) => {
        setLoading(true);
        let response = {};
        try {
             response = await cardPayNowReq(data, dispatchBy);
            console.log('response in context ', response);
            if (response.status && response.status.toLowerCase() === "success") {
                customAlert('Transaction Completed Successfully', 'success');
                dispatchBy !== "payByEmail" && await getFinanceData();
                //await getFinanceData();
            } else {
                customAlert(response?.result, 'error');
            }
        }catch (error) {
            console.error('Error occurred during card payment:', error);
            customAlert('An error occurred while processing your request. Please try again later.', 'error');
        } finally {
            setLoading(false);
        }
        return response;
    }

    useEffect(() => {
        if (pathname.toLowerCase().toString() === "/invoice") {
            getFinanceData();
        }
    }, [pathname]);

    return (
        <InvoiceContext.Provider value={{
            isLoading: loading,
            invoiceData,
            receiptData,
            unpaidInvoice,
            accountStatements,
            getFinanceData,
            cardPayNow,
        }}>
            {children}
        </InvoiceContext.Provider>
    )
}
