import React, {useRef, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Agreement} from "./compnents/Agreement";

export const AgreementModal = ({show, onClose, invoice, handleAcceptTerm}) => {
    const [isScrolled, setIsScrolled] = useState(true)
    const modalBodyRef = useRef(null);
    const handleClose = () => {
        onClose();
    };
    const handleScroll = (e) => {
        const modalBody = modalBodyRef.current;
        if (modalBody) {
            const isBottom = modalBody.scrollTop + modalBody.clientHeight === modalBody.scrollHeight;
            setIsScrolled(!isBottom);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" className="customModalClass" dialogClassName="agreementModal" onScroll={handleScroll}>
            <Modal.Header closeButton className="bg-primary py-2 px-6">
                <Modal.Title className="text-white">Credentialing Services Agreement</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-scroll" ref={modalBodyRef} onScroll={handleScroll}>
                <Row>
                    <Agreement userName={invoice?.customer_name} discount={invoice?.discount} payable={invoice?.payable} totalApps={invoice?.invoice_details?.length}/>
                    <Col md={12} className='my-3 d-flex justify-content-between'>
                        <Button onClick={handleClose} className='btn float-start btn-primary'>Close</Button>
                            <Button onClick={() => { handleAcceptTerm(); handleClose(); }} className="btn float-end me-3 py-2 px-4 btn-primary">
                                I Agree
                            </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 p-0">
                {isScrolled && (
                    <div className="agreeBtnDiv">
                        <Button onClick={() => { handleAcceptTerm(); handleClose(); }} className="btn float-end btn-primary agreeBtn">I Agree</Button>
                    </div>
                    )}
            </Modal.Footer>
        </Modal>
    )
}
