import {createContext, useEffect, useState} from "react"
import {getUserInfoReq,resetPasswordReq, addCardDetailReq} from "./_request"
import {customAlert} from "../../general/helpers";
export const ProfileContext = createContext()
export const ProfileContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState(false)

    useEffect(() => {
        getProfileInfo();
    }, [])

    const getProfileInfo = async () => {
        setLoading(true)
        let data = await getUserInfoReq()
        setUserInfo(data)
        setLoading(false)
    };
    const resetPassword = async (data) => {
        setLoading(true)
        let resp = await resetPasswordReq(data)
        if(resp.status.toLowerCase() === "success"){
            customAlert('Updated Successfully', 'success');
            await getProfileInfo();
        }else{
            customAlert('Something went wrong try again later!, please try again', 'error');
        }
        setLoading(false)
        return resp
    };
    const addCardDetail = async (data) => {
        setLoading(true)
        let resp = await addCardDetailReq(data)

        setLoading(false)
        return resp
    };
    return (
        <ProfileContext.Provider value={{
            loading,
            userInfo,
            getProfileInfo,
            resetPassword,
            addCardDetail,
        }}>
            {children}
        </ProfileContext.Provider>
    )
}