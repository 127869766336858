import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_USER_INO_URL = REACT_APP_BACKEND_API_URL+"get_user_info"
const UPDATE_PERSONAL_INFO_URL = REACT_APP_BACKEND_API_URL+"update_personal_info"
const UPDATE_LOGIN_INFO_URL = REACT_APP_BACKEND_API_URL+"update_login_info"
const UPDATE_DOCUMENT_URL = REACT_APP_BACKEND_API_URL+"update_document"
const ADD_PROVIDER_PRACTICES_URL = REACT_APP_BACKEND_API_URL+"add_provider_practices"
const ADD_USER_INSURANCES_URL = REACT_APP_BACKEND_API_URL+"add_user_insurances";
const GET_PRACTICES_URL = REACT_APP_BACKEND_API_URL+"get_practices";
const ADD_INSURANCES_WITH_CREDENTIAL_URL = `${REACT_APP_BACKEND_API_URL}add_insurance_with_credential`;
const GET_INSURANCES_LIST_URL = `${REACT_APP_BACKEND_API_URL}get_insurance_list`;
const REMOVE_INSURANCES_URL = `${REACT_APP_BACKEND_API_URL}remove_insurance_by_customer`;
const REMOVE_DOCS_URL = `${REACT_APP_BACKEND_API_URL}remove_portal_docs`;

let getUserInfoReq = async () => {
    try {
        const d = await axios
            .get(`${GET_USER_INO_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in getUserInfoReq() : ", error);
        throw error;
    }
}
let updatePersonalInfoReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_PERSONAL_INFO_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in updatePersonalInfoReq() : ", error);
        throw error;
    }
}
let updateLoginInfoReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_LOGIN_INFO_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in update updateLoginInfoReq() : ", error);
        throw error;
    }
}
let updateDocumentReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_DOCUMENT_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in logOut updateDocumentReq() : ", error);
        throw error;
    }
}
let addPracticesReq = async (data) => {
    try {
        const d = await axios
            .post(`${ADD_PROVIDER_PRACTICES_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in add Practices addPracticesReq() : ", error);
        throw error;
    }
}
let addInsurancesReq = async (data) => {
    try {
        const d = await axios
            .post(`${ADD_USER_INSURANCES_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in add addInsurancesReq() : ", error);
        throw error;
    }
}
let getPracticesReq = async (credential_id) => {
    try {
        const d = await axios
            .get(`${GET_PRACTICES_URL}/${credential_id}`);
        return d.data;
    } catch (error) {
        console.error("Error in getPracticesReq : ", error);
        throw error;
    }
}
const addInsurancesWithCredentialReq = async (data) => {
    try {
        const d = await axios
            .post(`${ADD_INSURANCES_WITH_CREDENTIAL_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in addInsurancesWithCredentialReq : ", error);
        throw error;
    }
}
const getSelectedInsurancesReq = async (credential_id, user_id) => {
    try {
        const d = await axios
            .get(`${GET_INSURANCES_LIST_URL}/${credential_id}/${user_id}`);
        return d.data;
    } catch (error) {
        console.error("Error in getSelectedInsurancesReq : ", error);
        throw error;
    }
}
const removeInsurancesReq = async (data) => {
    try {
        const d = await axios
            .post(`${REMOVE_INSURANCES_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in removeInsurancesReq : ", error);
        throw error;
    }
}
const removeCustomerDocsData = async (data) => {
    try {
        const d = await axios
            .post(`${REMOVE_DOCS_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in removeCustomerDocsData : ", error);
        throw error;
    }
}

export {
    getUserInfoReq,
    updateDocumentReq,
    updatePersonalInfoReq,
    updateLoginInfoReq,
    addPracticesReq,
    addInsurancesReq,
    getPracticesReq,
    addInsurancesWithCredentialReq,
    getSelectedInsurancesReq,
    removeInsurancesReq,
    removeCustomerDocsData
}