import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {UserContext} from "../context/UserContext";
import {useNavigate} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {KTSVG} from "../../general/KTSVG";
import {customAlert} from "../../general/helpers";

export const PersonalInfo = ({userInfo, logins, type, onSavePractices, isPracticesAdd=false}) => {
    // console.log('user info here ', userInfo, logins, type)
    const navigate = useNavigate();
    const {updatePersonalInfo} = useContext(UserContext);
    const [showPractice, setShowPractice] = useState('d-none')
    const [showProvider, setShowProvider] = useState('d-none')
    const [serviceAddresses, setServiceAddresses] = useState([]);
    const initialFormData = {
        credential_id:'',
        form_type:'',
        group_name:'',
        group_npi:'',
        group_tax_id:'',
        owner_dob:'',
        provider_name:'',
        provider_npi:'',
        ssn_number:'',
        service_address:'',
        billing_mailing_address:'',
        specialty:'',
        license_number:'',
        home_address:'',
        mailing_address: '',
        practice_phone: '',
        practice_fax: '',
        email: '',
        business_hours: '',

        // login variables
        login_id: '',
        nppes_username: '',
        nppes_password: '',
        caqh_username: '',
        caqh_password: '',
        provider_source_username: '',
        provider_source_password: '',
        availity_state: '',
        availity_username: '',
        availity_password: '',
        uhc_username: '',
        uhc_password: '',
    };
    const [form, setForm] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const handleSaveData = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('credential_id', form.credential_id);
        formData.append('form_type', userInfo.form_type);
        formData.append('provider_name', form.provider_name);
        formData.append('provider_npi', form.provider_npi);
        formData.append('group_name', form.group_name);
        formData.append('group_npi', form.group_npi);
        formData.append('group_tax_id', form.group_tax_id);
        formData.append('home_address', form.home_address);
        formData.append('ssn_number', form.ssn_number);
        formData.append('billing_mailing_address', form.billing_mailing_address);
        formData.append('service_address', JSON.stringify(serviceAddresses.map(address => address.value)));
        // formData.append('service_address', form.service_address);
        formData.append('specialty', form.specialty);
        formData.append('owner_dob', form.owner_dob);
        formData.append('license_number', form.license_number);
        formData.append('practice_phone', form.practice_phone);
        formData.append('practice_fax', form.practice_fax);
        formData.append('email', form.email);
        formData.append('mailing_address', form.mailing_address);
        formData.append('business_hours', form.business_hours);

        // login variables
        formData.append('login_id', logins.id);
        formData.append('nppes_username', form.nppes_username);
        formData.append('nppes_password', form.nppes_password);
        formData.append('caqh_username', form.caqh_username);
        formData.append('caqh_password', form.caqh_password);
        formData.append('provider_source_username', form.provider_source_username);
        formData.append('provider_source_password', form.provider_source_password);
        formData.append('availity_state', form.availity_state);
        formData.append('availity_username', form.availity_username);
        formData.append('availity_password', form.availity_password);
        formData.append('uhc_username', form.uhc_username);
        formData.append('uhc_password', form.uhc_password);

        // serviceAddresses.forEach((address, index) => {
        //     formData.append(`service_address[${index}]`, address.value);
        // });
        const validationErrors = {};
        if (!form.specialty) {
            validationErrors.specialty = 'Specialty is Required!';
        }
        if (Object.keys(validationErrors).length === 0) {
            let response = await updatePersonalInfo(formData);
            setErrors({});

            if(response.status.toLowerCase() === 'success'){
                setLoading(false);
                customAlert('Details Added!', 'success');
            }
            let item = response.data;
            // if(isPracticesAdd){
            //     await onSavePractices();
            // }
            navigate('/user-details/'+item.credential_id+'/edit', { state: { item: item, userData:userInfo } });
        }else {
            setErrors(validationErrors);
            setLoading(false);
        }
    };

    useEffect(() => {
        if(type == 'Practice'){
            setShowPractice('d-block')
            setShowProvider('d-none')
        } else {
            setShowPractice('d-none')
            setShowProvider('d-block')
        }
        if(userInfo) {
            const parsedServiceAddresses = JSON.parse(userInfo?.service_address || '[]');
            setServiceAddresses(
                parsedServiceAddresses.length > 0
                    ? parsedServiceAddresses.map((address, index) => ({ id: uuidv4(), value: address }))
                    : [{ id: uuidv4(), value: "" }]
            );
            setForm({
                form_type: userInfo.form_type ? userInfo.form_type: null,
                credential_id: userInfo.credential_id ? userInfo.credential_id: null,

                // group inputs
                group_name: userInfo.group_name ? userInfo.group_name: null,
                group_npi: userInfo.group_npi ? userInfo.group_npi: null,
                group_tax_id: userInfo.group_tax_id ? userInfo.group_tax_id: null,
                service_address: userInfo.service_address ? userInfo.service_address: null,
                billing_mailing_address: userInfo.billing_mailing_address ? userInfo.billing_mailing_address: null,
                mailing_address: userInfo.mailing_address ? userInfo.mailing_address: null,
                practice_phone: userInfo.practice_phone ? userInfo.practice_phone: null,
                practice_fax: userInfo.practice_fax ? userInfo.practice_fax: null,
                email: userInfo.email ? userInfo.email: null,

                business_hours: userInfo.business_hours ? userInfo.business_hours: null,

                // provider inputs
                specialty: userInfo.specialty ? userInfo.specialty: null,
                provider_name: userInfo.provider_name ? userInfo.provider_name: null,
                provider_npi: userInfo.provider_npi ? userInfo.provider_npi: null,
                owner_dob: userInfo.owner_dob ? userInfo.owner_dob: null,
                ssn_number: userInfo.ssn_number ? userInfo.ssn_number: null,
                license_number: userInfo.license_number ? userInfo.license_number: null,

                //     logins variables
                home_address: userInfo.home_address ? userInfo.home_address: null,
                login_id: logins.id ? logins.id: null,
                nppes_username: logins.nppes_username,
                nppes_password: logins.nppes_password,
                caqh_username: logins.caqh_username,
                caqh_password: logins.caqh_password,
                provider_source_username: logins.provider_source_username,
                provider_source_password: logins.provider_source_password,
                availity_state: logins.availity_state,
                availity_username: logins.availity_username,
                availity_password: logins.availity_password,
                uhc_username: logins.uhc_username,
                uhc_password: logins.uhc_password,
            })
        }
    }, [userInfo, logins, type]);
    const addServiceAddress = () => {
        setServiceAddresses((prevAddresses) => [...prevAddresses, { id: uuidv4(), value: '' }]);
    };
    const handleServiceAddressChange = (index, value) => {
        setServiceAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses[index].value = value;

            setForm((prevForm) => ({
                ...prevForm,
                service_address: updatedAddresses.map((address) => address.value),
            }));

            return updatedAddresses;
        });

        // setForm((prevForm) => ({
        //     ...prevForm,
        //     service_address: serviceAddresses.map((address) => address.value),
        // }));
    };
    const removeServiceAddress = (index) => {
        setServiceAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses.splice(index, 1);
            return updatedAddresses;
        });
    };


    return (
        <>
            <div>
                {loading && (
                    <div className="loadingOverLay">
                        <h4>Processing!. Please Wait...!</h4>
                    </div>
                )}
                <h6 className="header-text text-uppercase">Details</h6>
                <Row>
                    <Col md={6} className={showPractice}>
                        <div className={`form-group mb-3 `+showPractice}>
                            <label htmlFor='group_name'>Group Name</label>
                            <input
                                id='group_name'
                                className={`form-control ${errors.group_name ? 'is-invalid' : ''}`}
                                value={form?.group_name =='null' ? '' : form.group_name}
                                onChange={(e) => setForm({ ...form, group_name: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className={`form-group mb-3`}>
                            <label htmlFor='group_npi'>Group NPI</label>
                            <input
                                id='group_npi'
                                className={`form-control ${errors.group_npi ? 'is-invalid' : ''}`}
                                value={form.group_npi == 'null' ? '' : form.group_npi}
                                onChange={(e) => setForm({ ...form, group_npi: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className={`form-group mb-3`}>
                            <label htmlFor='group_tax_id'>Group Tax ID</label>
                            <input
                                id='group_tax_id'
                                className={`form-control ${errors.group_tax_id ? 'is-invalid' : ''}`}
                                value={form.group_tax_id == 'null' ? '' : form.group_tax_id}
                                onChange={(e) => setForm({ ...form, group_tax_id: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group mb-3'>
                            <label htmlFor='provider_name'>Provider Name <span className="text-primary fw-bold">*</span></label>
                            <input
                                id='provider_name'
                                className='form-control'
                                value={form.provider_name == 'null' ? '' : form.provider_name}
                                onChange={(e) => setForm({ ...form, provider_name: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group mb-3'>
                            <label htmlFor='provider_npi'>Provider NPI <span className="text-primary fw-bold">*</span></label>
                            <input
                                id='provider_npi'
                                className='form-control'
                                value={form.provider_npi == 'null' ? '' : form.provider_npi}
                                onChange={(e) => setForm({ ...form, provider_npi: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='form-group mb-3'>
                            <label htmlFor='specialty'>Specialty <span className="text-primary fw-bold">*</span></label>
                            <input
                                id='specialty'
                                className={`form-control ${errors.specialty ? 'is-invalid' : ''}`}
                                value={form.specialty == 'null' ? '' : form.specialty}
                                onChange={(e) => setForm({ ...form, specialty: e.target.value })}
                            />
                            {errors.specialty && <span className="invalid-feedback">{errors.specialty}</span>}
                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group mb-3'>
                            <label htmlFor='owner_dob'>DOB <span className="text-primary fw-bold">*</span></label>
                            <input
                                id='owner_dob'
                                type='date'
                                className={`form-control ${errors.owner_dob ? 'is-invalid' : ''}`}
                                value={form.owner_dob}
                                onChange={(e) => setForm({ ...form, owner_dob: e.target.value })}
                            />
                            {errors.owner_dob && <span className="invalid-feedback">{errors.owner_dob}</span>}
                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group mb-3'>
                            <label htmlFor='ssn_number'>SSN Number <span className="text-primary fw-bold">*</span></label>
                            <input
                                id='ssn_number'
                                className='form-control'
                                value={form.ssn_number == 'null' ? '' : form.ssn_number}
                                onChange={(e) => setForm({ ...form, ssn_number: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group mb-3'>
                            <label htmlFor='practice_phone'>Practice Phone </label>
                            <input
                                id='practice_phone'
                                className='form-control'
                                value={form.practice_phone == 'null' ? '' : form.practice_phone}
                                onChange={(e) => setForm({ ...form, practice_phone: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='practice_fax'>Practice Fax</label>
                            <input
                                id='practice_fax'
                                className='form-control'
                                value={(form?.practice_fax=='null' || form?.practice_fax=='undefined')?'':form.practice_fax}
                                onChange={(e) => setForm({ ...form, practice_fax: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='email'>Email</label>
                            <input
                                id='email'
                                className='form-control'
                                value={form.email == 'null' ? '' : form.email}
                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='business_hours'>Business Hours</label>
                            <input
                                id='business_hours'
                                className='form-control'
                                value={form.business_hours == 'null' ? '' : form.business_hours}
                                onChange={(e) => setForm({ ...form, business_hours: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='billing_mailing_address'>Billing Address</label>
                            <input
                                id='billing_mailing_address'
                                className='form-control'
                                value={form.billing_mailing_address == 'null' ? '' : form.billing_mailing_address}
                                onChange={(e) => setForm({ ...form, billing_mailing_address: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='mailing_address'>Mailing Address</label>
                            <input
                                id='mailing_address'
                                className='form-control'
                                value={form.mailing_address == 'null' ? '' : form.mailing_address}
                                onChange={(e) => setForm({ ...form, mailing_address: e.target.value })}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={showPractice}>
                        <div className='form-group mb-3'>
                            <label htmlFor='service_address'>Service Address</label>
                            {serviceAddresses.map((address, index) => (
                                <div key={address.id} className='mb-3 input-group'>
                                    <input
                                        id={`service_address_${index}`}
                                        type='text'
                                        name={`service_address_${index}`}
                                        className='form-control'
                                        value={address.value}
                                        placeholder={`Service Address ${index + 1}`}
                                        onChange={(e) => handleServiceAddressChange(index, e.target.value)}
                                    />
                                    {index === 0 && (
                                        <div className="input-group-append">
                                            <button
                                                className="btn bg-transparent btn-sm"
                                                title="Add More"
                                                type="button"
                                                onClick={addServiceAddress}
                                            >
                                                <KTSVG path="/svg/addmore.svg" />
                                            </button>
                                        </div>
                                    )}
                                    {index > 0 && (
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-transparent btn-sm"
                                                title="Remove"
                                                type="button"
                                                onClick={() => removeServiceAddress(index)}
                                            >
                                                <KTSVG path="/svg/delete.svg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='license_number'>License Number</label>
                            <input
                                id='license_number'
                                className={`form-control require ${errors.license_number ? 'is-invalid' : ''}`}
                                value={form.license_number == 'null' ? '' : form.license_number}
                                onChange={(e) => setForm({ ...form, license_number: e.target.value })}
                            />
                            {errors.license_number && <span className="invalid-feedback">{errors.license_number}</span>}

                        </div>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <div className='form-group  mb-3'>
                            <label htmlFor='home_address'>Home Address</label>
                            <input
                                id='home_address'
                                className='form-control'
                                value={form.home_address == 'null' ? '' : form.home_address}
                                onChange={(e) => setForm({ ...form, home_address: e.target.value })}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/*<h5 className="header-text mt-3 text-uppercase">Log in details</h5>*/}
                    </Col>
                </Row>
                <div className={showProvider}>
                    <Row>
                        <Col md={6}>
                            <h6 className="header-text">Provider Source Logins</h6>
                            <Row>
                                <Col md={12}>
                                    <div className='form-group  mb-3'>
                                        <label htmlFor='provider_source_username'>User Name</label>
                                        <input
                                            id='provider_source_username'
                                            className={`form-control ${errors.provider_source_username ? 'is-invalid' : ''}`}
                                            value={form.provider_source_username == 'undefined' ? '' : form.provider_source_username}
                                            onChange={(e) => setForm({ ...form, provider_source_username: e.target.value })}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form-group  mb-3'>
                                        <label htmlFor='provider_source_password'>Password</label>
                                        <input
                                            id='provider_source_password'
                                            className={`form-control ${errors.provider_source_password ? 'is-invalid' : ''}`}
                                            value={form.provider_source_password == 'undefined' ? '' : form.provider_source_password}
                                            onChange={(e) => setForm({ ...form, provider_source_password: e.target.value })}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <h6 className="header-text">CAQH Logins</h6>
                            <Row>
                                <Col md={12}>
                                    <div className='form-group  mb-3'>
                                        <label htmlFor='caqh_username'>User Name</label>
                                        <input
                                            id='caqh_username'
                                            className={`form-control ${errors.caqh_username ? 'is-invalid' : ''}`}
                                            value={form.caqh_username == 'undefined' ? '' : form.caqh_username}
                                            onChange={(e) => setForm({ ...form, caqh_username: e.target.value })}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form-group  mb-3'>
                                        <label htmlFor='caqh_password'>Password</label>
                                        <input
                                            id='caqh_password'
                                            className={`form-control ${errors.caqh_password ? 'is-invalid' : ''}`}
                                            value={form.caqh_password == 'undefined' ? '' : form.caqh_password}
                                            onChange={(e) => setForm({ ...form, caqh_password: e.target.value })}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={6}>
                        <h6 className="header-text">Availity Logins</h6>
                        <Row>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='availity_username'>User Name</label>
                                    <input
                                        id='availity_username'
                                        className={`form-control ${errors.availity_username ? 'is-invalid' : ''}`}
                                        value={form.availity_username == 'undefined' ? '' : form.availity_username}
                                        onChange={(e) => setForm({ ...form, availity_username: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='availity_password'>Password</label>
                                    <input
                                        id='availity_password'
                                        className={`form-control ${errors.availity_password ? 'is-invalid' : ''}`}
                                        value={form.availity_password=='undefined' ? '' : form.availity_password}
                                        onChange={(e) => setForm({ ...form, availity_password: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='availity_state'>State</label>
                                    <input
                                        id='availity_state'
                                        className={`form-control ${errors.availity_state ? 'is-invalid' : ''}`}
                                        value={form.availity_state=='undefined' ? '' : form.availity_state}
                                        onChange={(e) => setForm({ ...form, availity_state: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <h6 className="header-text">NPPES/PECOS Logins</h6>
                        <Row>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='nppes_username'>User Name</label>
                                    <input
                                        id='nppes_username'
                                        className={`form-control ${errors.nppes_username ? 'is-invalid' : ''}`}
                                        value={form.nppes_username=='undefined' ? '' : form.nppes_username}
                                        onChange={(e) => setForm({ ...form, nppes_username: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='nppes_password'>Password</label>
                                    <input
                                        id='nppes_password'
                                        className={`form-control ${errors.nppes_password ? 'is-invalid' : ''}`}
                                        value={form.nppes_password == 'undefined' ? '' : form.nppes_password}
                                        onChange={(e) => setForm({ ...form, nppes_password: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className={showProvider}>
                        <h6 className="header-text">UHC Portal Logins</h6>
                        <Row>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='uhc_username'>User Name</label>
                                    <input
                                        id='uhc_username'
                                        className={`form-control ${errors.uhc_username ? 'is-invalid' : ''}`}
                                        value={form.uhc_username == 'undefined' ? '' : form.uhc_username}
                                        onChange={(e) => setForm({ ...form, uhc_username: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-group  mb-3'>
                                    <label htmlFor='uhc_password'>Password</label>
                                    <input
                                        id='uhc_password'
                                        className={`form-control ${errors.uhc_password ? 'is-invalid' : ''}`}
                                        value={form.uhc_password == 'undefined' ? '' : form.uhc_password}
                                        onChange={(e) => setForm({ ...form, uhc_password: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="text-end mt-5 mt-md-0">
                    <Button variant="primary btn-sm px-5 fs-5 me-3 userDetailSave" disabled={loading} onClick={handleSaveData} >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </div>
        </>
    )
}
