import {Col, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import {InvoiceContext} from "../../context/InoviceContext";
import {AgreementModal} from "../AgreementModal";
import ReCAPTCHA from "react-google-recaptcha";

export const PayNowForm = ({invoice, dispatchBy=''}) => {
    const {cardPayNow} = useContext(InvoiceContext);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showAgreement, setShowAgreement] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    let dueAmount = parseFloat(invoice.total_amount);
    let minimumPayable=0;
    //percentage algorithm
    if(isNaN(parseFloat(invoice.paid_amount))){
        let payablePercetage = (invoice.payable/100);
        minimumPayable = invoice.total_amount * payablePercetage;
    } else {
        dueAmount -= parseFloat(invoice.paid_amount)
        minimumPayable = dueAmount;
    }
    let [payAmount, setPayAmount] = useState(minimumPayable)
    let handlePayment = async (e) => {
        e.preventDefault();
        if(!isVerified) {
            setErrorCaptcha('Captcha is Required!');
            return;
        }
        let formData = new FormData(e.target);
        formData.append('invoice_id', invoice?.invoice_id);
        formData.append('remaining_due', dueAmount-payAmount);
        let response = await cardPayNow(formData, dispatchBy);
        //console.log('response in model', response)
    }
    const handleCaptchaChange = (response) => {
        if (response) {
            setIsVerified(true);
            console.log("Verified successfully:");
        } else {
            console.error("Verification failed!");
        }
    };
    return (
        <form onSubmit={(e)=>{handlePayment(e)}}>
            <Row className="px-3">
                <Col md={12} className="mb-3 d-flex align-items-center justify-content-between">
                    <h5 className='header-text'>Payment Details</h5>
                    <img src="/img/visa.png" className="img-fluid me-3" alt="visa"/>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">Due Amount</label>
                        <input type='text' disabled value={dueAmount} className="form-control"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">Pay Amount</label>
                        <input name="payable" required type='number' min={minimumPayable} value={payAmount} className="form-control"
                               onChange={(e)=>{setPayAmount(e.target.value)}} max={dueAmount}/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">Account Holder Name</label>
                        <input name="full_name" required type='text' className="form-control"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">Card Number</label>
                        <input name="card_number" required type='text' className="form-control"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">CVV</label>
                        <input name="cvv" required type='text' className="form-control"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='form-group mb-3'>
                        <label className="f-w600 mb-2">Expiry Date</label>
                        <input name="exp_date" required type='month' className="form-control"/>
                    </div>
                </Col>
                <Col md={12}>
                    {dispatchBy == 'payByEmail' ? (
                        <>
                            <div className="text-start d-flex align-items-center">
                                <input type="checkbox" name="terms" className="form-check-input ms-1 form-control termCheckBox border-primary" id="terms" onChange={(e)=>{setTermsAccepted(e.target.checked)}} checked={termsAccepted} />
                                <p className="text-center pt-3 ps-3 f-w500">Check here to indicate that you have read and agree to the terms of <span className="textPrimary cursor-pointer" onClick={ () => setShowAgreement(true)}>Credentialing Service Agreement.</span></p>
                            </div>
                            {showAgreement && <AgreementModal invoice={invoice} show={showAgreement} handleAcceptTerm={() => setTermsAccepted(true)} onClose={()=>{setShowAgreement(false)}}/> }
                        </>
                    ) : (
                        <div className="text-start d-flex align-items-center">
                            <input type="checkbox" name="save_card"
                                   className="form-check-input ms-0 form-control termCheckBox border-primary"
                                   id="save_card"/>
                            <p className="text-center pt-3 ps-3 f-w500">Save Payment Details </p>
                        </div>
                    )}
                </Col>
                <Col md={12}>
                    <div className='fv-row mb-3 mt-2'>
                        <div className={`d-flex ${errorCaptcha ? 'is-invalid' : ''}`}>
                            <ReCAPTCHA
                                sitekey="6LfuNqApAAAAABGz_949SdMD8p4GhBlgwc6rUUWx"
                                onChange={handleCaptchaChange}
                                style={{transform: 'scale(0.8)', transformOrigin: '0 0'}}
                            />
                        </div>
                        {errorCaptcha && <span className="invalid-feedback fw-bold">{errorCaptcha}</span>}
                    </div>
                </Col>

                <Col>
                    {dispatchBy == 'payByEmail' ? (
                        <button type="submit" className="btn btn-primary float-end me-2 my-3 px-4 py-2"
                                disabled={!termsAccepted}>Pay Now</button>
                    ) : (
                        <button type="submit" className="btn btn-primary float-end me-2 my-3 px-4 py-2">Pay Now</button>
                    )}
                < /Col>
            </Row>
        </form>
    )
}
