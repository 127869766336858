import {PayNowForm} from "./modal/compnents/PayNowForm";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../login/context/AuthContext";
import {Loader} from "../general/Loader";
import {Col, Row} from "react-bootstrap";
import {formatDateDMY} from "../general/utils";
import {PayMaintananceByEmail} from "./modal/compnents/PayMaintananceByEmail";

export const InvoicePayByEmail = () => {
    const {invoicePayLinkVerify} = useContext(AuthContext);
    const { token } = useParams();
    const navigate = useNavigate();
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    useEffect(() => {
        if(token){
            setInvoiceLoading(true);
            invoicePayLinkVerify(token).then(res => {
                if(res.status.toLowerCase() === 'success'){
                    setInvoiceData(res?.result);
                    setInvoiceLoading(false);
                }else {
                    let data = res?.result;
                    navigate('/login', {state: {data}});
                    setInvoiceLoading(false);
                }
            });
        }
        setInvoiceLoading(false);
    }, []);

    return (
        <div className='container vh-100 mt-5 pt-3'>
            {invoiceLoading ? <Loader/> : (
                <div className='vh-100'>
                    <Row>
                        <Col md={6}>
                            <img alt='Logo' style={{width:160}} src={process.env.PUBLIC_URL + '/logo.png'} className='p-3'/>
                            <h5 className='mt-3 mb-3 ps-3'>{invoiceData.title}</h5>
                        </Col>
                        <Col md={6} className="text-end pe-4">
                            <h5>INVOICE:</h5>
                            <h5># INV-{invoiceData?.invoice_id}</h5>
                            <h5>Amount: {invoiceData?.total_amount}</h5>
                            <p>Due Date: {formatDateDMY(invoiceData?.due_date)}</p>
                        </Col>
                    </Row>
                        <hr/>
                    {/*<PayMaintananceByEmail />*/}
                    <PayNowForm invoice={invoiceData} dispatchBy="payByEmail"/>
                </div>
                )}
        </div>
    )

}