
import React, {useContext} from 'react';
import {AuthContext} from "./login/context/AuthContext";
import {KTSVG} from "./general/KTSVG";

const LogoutButton = () => {
    const {logOut} = useContext(AuthContext);
    const logOutCall = () => {
        logOut();
    };

    return (
        <button className={`btn btn-transparent border-0 d-flex align-items-center justify-content-between`} onClick={logOutCall} >
            <KTSVG path='/svg/logout.svg' className='fs-1 text-success fw-bold'/>
            <span className="align-items-center ms-4 mt-1 text-primary">Logout</span>
        </button>
    );
};

export default LogoutButton;
