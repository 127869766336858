import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserDetails from "../customer/UserDetails";
import Dashboard from "../dashboard/Dashboard";
import LayoutWrapper from "../layouts/LayoutWrapper";
import UserDetailsForm from "../customer/UserDetailsForm";
import {UserContextProvider} from "../customer/context/UserContext";
import AppList from "../applications/AppList";
import {AppContextProvider} from "../applications/context/AppContext";
import AppDetails from "../applications/components/AppDetails";
import {DashboardContextProvider} from "../dashboard/context/DashboardContext";
import DocList from "../documents/DocList";
import Profile from "../profile/Profile";
import {DocContextProvider} from "../documents/context/DocContext";
import {ProfileContextProvider} from "../profile/context/ProfileContext";
import {Invoice} from "../invoices/Invoice";
import {InvoiceContextProvider} from "../invoices/context/InoviceContext";

const PrivateRoute = () => {
    return (
        <Routes>
            <Route element={ <LayoutWrapper /> }>
                <Route path="/*" element={<Navigate to={'/dashboard'} />} />
                <Route index element={<Navigate to={'/dashboard'} />} />
                <Route
                    path='user-details'
                    element={
                        <UserContextProvider>
                            <UserDetails />
                        </UserContextProvider>
                    }
                />
                <Route path='/user-details/add' element={
                    <UserContextProvider>
                        <UserDetailsForm />
                    </UserContextProvider>
                } />
                <Route path='user-details/:credential_id/edit' element={
                    <UserContextProvider>
                        <UserDetailsForm />
                    </UserContextProvider>
                } />
                <Route path='/application' element={
                    <AppContextProvider>
                        <AppList />
                    </AppContextProvider>
                } />
                <Route path='/application/:app_id' element={
                    <AppContextProvider>
                        <AppDetails />
                    </AppContextProvider>
                } />
                <Route path='/documents' element={
                    <DocContextProvider>
                        <DocList />
                    </DocContextProvider>
                } />
                <Route path='/profile' element={
                    <ProfileContextProvider>
                        <Profile />
                    </ProfileContextProvider>
                } />
                <Route path='dashboard' element={
                    <DashboardContextProvider>
                        <Dashboard />
                    </DashboardContextProvider>
                } />
                <Route path='invoice' element={
                    <InvoiceContextProvider>
                        <Invoice />
                    </InvoiceContextProvider>
                } />
            </Route>
        </Routes>
    );
};

export default PrivateRoute;