import React, {useContext, useEffect, useState} from 'react'
import {Link, useNavigate } from "react-router-dom"
import {UserContext} from "./context/UserContext"
import UserDetailsForm from "./UserDetailsForm"
import {Loader} from "../general/Loader"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {HeaderTitle} from "../general/HeaderTitle";
import {KTSVG} from "../general/KTSVG";
import {bgImgFunc} from "../general/utils";
import Swal from "sweetalert2";
import {NotFound} from "../general/NotFound";

const UserDetails = () => {
    const navigate = useNavigate();

    const {loading, getUserInfo, userData, addInsurances, removeInsuranceData} = useContext(UserContext)
    const [selectedInsurances, setSelectedInsurances] = useState([])
    const [showAddInsuranceBtn, setShowAddInsuranceBtn] = useState(false)
    const animatedComponents = makeAnimated()

    let item = { form_type:'credentialing_agencies' }
    const handleMultiSelectChange = (selectedInsurances) => {
        setSelectedInsurances(selectedInsurances)
        if(selectedInsurances.length>0){
            setShowAddInsuranceBtn(true)
        }else{
            setShowAddInsuranceBtn(false)
        }
    }
    const addInsurance = () => {
        let selectedPractices = selectedInsurances.map((name) => (
            name.value
        ))
        let formData = new FormData()
        formData.append('insurances', selectedPractices)
        if(selectedInsurances.length>0){
            addInsurances(formData)
            setSelectedInsurances([])
            setShowAddInsuranceBtn(false)
        }
    }
    const removeInsurance = async (applicationId, insuranceId, userId) => {
        const confirmationMessage = await Swal.fire({
            title: "Are you sure?",
            text: "Currently no applications linked. You will able to add again, ",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: '#1E6FE0',
            cancelButtonColor: '#dc3545',
            confirmButtonText: "Yes, Remove!",
            cancelButtonText: "Cancel",
            dangerMode: true,
        });
        if(confirmationMessage.isConfirmed){
            const formData = new FormData();
            formData.append('application_id', applicationId);
            formData.append('insurance_id', insuranceId);
            formData.append('user_id', userId);
            removeInsuranceData(formData);
        }
    }
    const addNewProvider = () => {
        item = {
            form_type:'credentialing_individual_provider',
        }
        navigate('/user-details/add', { state: { item: item, userData:userData } });
    };
    useEffect(() => {
        getUserInfo()
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12">
                        <HeaderTitle title="User Details" />
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <div className="card bg-transparent rounded-5 border-top-0">
                                    <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                        <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Practices</h4>
                                        <Link to= {{ pathname: '/user-details/add' }} state={{item}} className="btn"><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg" /> </span></Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="user-info-list pe-2">
                                            {userData?.practices?.map((item, index) => (
                                                <Link
                                                    to= {{ pathname: '/user-details/'+item.credential_id+'/edit' }}
                                                    state={{item}}
                                                    key={'prac'+index}
                                                    className="bg-gray-list mt-3 text-start btn w-100">
                                                    <span className="f-w500">{item?.group_name} - {item?.group_npi}</span>
                                                    <span className="float-end align-middle align-items-center mtMinus5">
                                                           {
                                                               item?.credential_group_status_count
                                                               && (item.credential_group_status_count.approved_count > 26
                                                               || item.credential_group_status_count.unapproved_count === 0)  ? (
                                                               <KTSVG path="/svg/success-check.svg" className="fs-3 mt-2 fw-bold me-4" />
                                                       ) : (
                                                           <KTSVG path="/svg/error.svg" className="fs-3 mt-2 fw-bold me-4" />
                                                       )}
                                                        <KTSVG path="/svg/forward.svg" className="fs-3 mt-2 fw-bold me-2" />
                                                    </span>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <div className="card bg-transparent rounded-5 border-top-0">
                                    <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                        <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Providers</h4>
                                        <button onClick={addNewProvider} className="btn"><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg" /> </span></button>
                                    </div>
                                    <div className="card-body">
                                        <div className="user-info-list pe-2">
                                            {/*<button onClick={addNewProvider} className="active-bg-custom btn w-100"><span className="text-primary f-w500">Add Provider</span></button>*/}
                                            {/*<Link id="show_provider_btn_id" to= {{ pathname: '/user-details/add' }} state={{item}} className="d-none active-bg-custom btn w-100"><span className="text-primary f-w500">Add Practice</span></Link>*/}
                                            {userData?.providers?.map((item, index) => (
                                                <Link to= {{ pathname: '/user-details/'+item.credential_id+'/edit' }}
                                                      state={{item, userData}} key={'pro'+index} className="bg-gray-list mt-3 text-start btn w-100 align-middle align-items-center">
                                                    <span className="f-w500">{item.provider_name} - {item.provider_npi}</span>
                                                    <span className="float-end align-middle align-items-center mtMinus5">
                                                       {item?.credential_indiviudal_status_count && (item.credential_indiviudal_status_count.approved_count > 25 || item.credential_indiviudal_status_count.unapproved_count === 0)  ? (
                                                           <KTSVG path="/svg/success-check.svg" className="fs-3 mt-2 fw-bold me-4" />
                                                       ) : (
                                                           <KTSVG path="/svg/error.svg" className="fs-3 mt-2 fw-bold me-4" />
                                                       )}
                                                        <KTSVG path="/svg/forward.svg" className="fs-3 mt-2 fw-bold me-2" />
                                                    </span>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-12">
                                <div className="card radius-15">
                                    <h4 className="card-header card-header-style text-white">Payers</h4>
                                    <div className="card-body mb-3">
                                        <Select
                                            isMulti
                                            className="my-3 d-none"
                                            components={animatedComponents}
                                            placeholder="Search / Add Insurance"
                                            value={selectedInsurances}
                                            onChange={handleMultiSelectChange}
                                            options={userData?.insurances?.map((list) => ({
                                                value: list.id,
                                                label: list.title,
                                            }))}
                                        />
                                        <button
                                            onClick={addInsurance}
                                            className={`${showAddInsuranceBtn?'d-block':'d-none'} btn btn-primary fw-bold btn-sm mb-2 mt-2 float-end`}
                                            disabled={loading}
                                        >{loading ? 'Loading...' : 'Add Insurance'}</button>
                                        {userData?.payers?.length > 0 ? userData?.payers?.map((item, index) => (
                                            <>
                                            <a key={'pay'+index} className="bg-gray-list mt-3 text-start btn mx-2">
                                                <span className="fw-bold">{item.insurance.title}</span>
                                            </a>
                                            {item?.app_count == 0 && (<span onClick={ ()=> removeInsurance(item?.user_insurance_id, item?.insurance_id, item?.user_id)} className="btn btn-icon btn-circle btn-color-muted btn-active-color-danger bg-body shadow insuranceRemove" title="remove"><i className="fa fa-times text-danger fs-6 removeIcon"></i></span>)}
                                            </>
                                        )) : (<NotFound message="Payers not selected" />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default UserDetails