import React from 'react';

const Loader = () => {
    return (
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginTop: '-4em' }}>
            <div className='mt-0'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 100 100"
                >
                    <circle
                        cx="50"
                        cy="50"
                        r="45"
                        fill="none"
                        strokeWidth="5"
                        stroke="#0073e6"
                        strokeDasharray="283"
                        strokeDashoffset="0"
                    >
                        <animate
                            attributeName="stroke-dashoffset"
                            dur="2s"
                            repeatCount="indefinite"
                            keyTimes="0;1"
                            values="0;283"
                        />
                    </circle>
                </svg>
                <div className="mt-2">Loading...</div>
            </div>
        </div>
    );
}

export { Loader };
