import React, { useState } from 'react';

export const CustomPagination = ({ totalRecords, recordsPerPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    const updatePage = (e,page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={(e) => updatePage(e,i)} className='page-link'>{i}</button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        {renderPageNumbers()}
                    </ul>
                </div>
            </div>
        </div>
    );
};
