import axios1 from "../../../axios_handler";
import axios from 'axios';
const {REACT_APP_BACKEND_API_URL, REACT_APP_RCM_BACKEND_API_URL, REACT_APP_CRM_PORTAL_API_KEY} = process.env;
const GET_FINANCE_DATA = `${REACT_APP_BACKEND_API_URL}user_finance_details`;
const UPDATE_INVOICE_URL = `${REACT_APP_BACKEND_API_URL}update_invoice_data`;
const CARD_PAY_NOW_URL = `${REACT_APP_BACKEND_API_URL}card_pay_now`;
const CARD_PAY_EMAIL_URL = `${REACT_APP_BACKEND_API_URL}card_pay_now_by_email`;

const financeData = async () => {
    try{
        const data = new FormData();
        // here we send public_user_id mean user id to customer portal
        const d = await axios1
            .get(`${GET_FINANCE_DATA}`);
        return d.data;
    }catch (error) {
        console.log('Error in financeData () : ', error);
        throw error;
    }
}
const updateInvoiceReq = async (data) => {
    try{
        const d = await axios1
            .post(`${UPDATE_INVOICE_URL}`, data);
        return d.data;
    }catch (error) {
        console.log('Error in financeData () : ', error);
        throw error;
    }
}
const cardPayNowReq = async (data, dispatchBy) => {
    try{
        let d = {};
        if(dispatchBy == 'payByEmail'){
            d = await axios
                .post(`${CARD_PAY_EMAIL_URL}`, data);
        }else{
             d = await axios1
                .post(`${CARD_PAY_NOW_URL}`, data);
        }
        return d.data;
    }catch (error) {
        console.log('Error in financeData () : ', error);
        throw error;
    }
}

export {financeData, cardPayNowReq, updateInvoiceReq};