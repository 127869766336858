import React from 'react';
import {AuthContextProvider} from "./components/login/context/AuthContext";
import {AppNavigation} from "./components/navigation/AppNavigation";

const App = () => {
    return (
        <AuthContextProvider>
            <AppNavigation/>
        </AuthContextProvider>
    );
};

export default App;
