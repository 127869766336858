import Swal from "sweetalert2";

/**
 * @param msg
 * @param status
 */
const customAlert = (msg, status) => {
    Swal.fire({
        title: status.toLowerCase() == 'success' ? 'Success' : 'Error',
        text: msg,
    })
}

export {customAlert}