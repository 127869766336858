import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_USER_DASHBOARD_URL = REACT_APP_BACKEND_API_URL+"get_user_dashboard_info"
const UPDATE_PERSONAL_INFO_URL = REACT_APP_BACKEND_API_URL+"update_personal_info"

let getUserDashboardReq = async () => {
    try {
        const d = await axios
            .get(`${GET_USER_DASHBOARD_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in User Dashboard Req() : ", error);
        throw error;
    }
}
let updatePersonalInfoReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_PERSONAL_INFO_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in update Persona lInfo Req() : ", error);
        throw error;
    }
}

export {getUserDashboardReq, updatePersonalInfoReq}