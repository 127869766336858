import React, {useContext, useEffect, useState} from 'react';
import {PieDonutChart} from "../general/PieDonutChart";
import {DashboardContext} from "./context/DashboardContext";
import {Loader} from "../general/Loader";
import {formatDateDMY} from "../general/utils";
import ReadMoreReact from 'read-more-react';
import {HeaderTitle} from "../general/HeaderTitle";
import {Link} from "react-router-dom";
const Dashboard = () => {

    const{loading, userDashboardData, getUserDashboard} = useContext(DashboardContext)
    const statusCounts = {
        paid: 0,
        pending: 0,
        submitted: 0,
        approved: 0,
        contracted: 0,
        outofservice: 0,
        marketclosed: 0
    };
    const [appUpdates, setAppUpdates] = useState([]);

    let totalAppCount = 0;
    userDashboardData && userDashboardData.app_stats &&
    userDashboardData?.app_stats.forEach((app_stat) => {
        totalAppCount += app_stat.count;
        const status = app_stat.insurance_status.toLowerCase();
        const normalizedStatus = status.replace(/\s+/g, '');
        // Check status is present
        if (statusCounts.hasOwnProperty(normalizedStatus)) {
            statusCounts[normalizedStatus] += parseInt(app_stat.count, 10);
        } else {
            statusCounts[normalizedStatus] = parseInt(app_stat.count, 10);
        }
    });
    useEffect(() => {
        setAppUpdates(userDashboardData.app_updates);
    }, [userDashboardData?.total_insurances, userDashboardData?.total_app, userDashboardData?.providers, userDashboardData?.practuces]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className="row mt-4">
                        <div className="col-12 mb-4">
                            <HeaderTitle title="Dashboard" />
                        </div>
                        <div className="col-md-4 d-md-flex">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">APPLICATIONS</h5>
                                <div className="card-body">
                                    <PieDonutChart
                                        data={ Object.values(statusCounts) }
                                        labels={['Paid','Pending', 'Submitted', 'Approved', 'Contracted', 'Out of Service', 'Market Closed']}
                                        colors={['#014d01','#ffc300','#1E6FE0','#008000', '#8032e6', '#c81824', '#ff0000']}
                                        legendOptions={{topY: 0}}
                                        total_app = {parseInt(userDashboardData.total_app)}
                                        paid_app = {parseInt(userDashboardData.total_paid_app)}
                                        dispatchBy="app_status"
                                        height="700"
                                        pieWidth="65"
                                        title="Application Status"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 d-md-flex mt-3 mt-md-0">
                            <div className="card radius-15 w-100">
                                <h5 className="card-header card-header-style text-white">UPDATES</h5>
                                <div className="card-body">
                                    <table className="table dashboardUpdateTbl">
                                        <thead className="tblGrayClr bg-gray py-4 fs-8 my-5">
                                        <tr className="update-table-th">
                                            <th className="text-theme-active ps-2">Application Name</th>
                                            <th className="text-theme-active">Status</th>
                                            <th className="text-theme-active">Last Message</th>
                                            <th className="text-theme-active">Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {appUpdates?.map((item, index) => (
                                            <tr key={index} className="data-table-row" style={{backgroundColor:'#F5F6F8'}}>
                                                <td className="ps-2">{ item?.credential?.form_type === 'credentialing_agencies' ? item.credential?.group_name : item.credential?.provider_name} ({item.insurance?.title})</td>
                                                <td className={`clr${item.insurance_status}`}>
                                                    {item.insurance_status}
                                                    <div className={item?.is_application_paid == 1 ? 'text-success' : 'text-danger'}>{item?.is_application_paid == 1 ? '(Paid)' : ''}</div>
                                                </td>
                                                <td>
                                                    {item?.insurance_timeline?.length>0?(
                                                        <ReadMoreReact
                                                            text={item?.insurance_timeline?.[0]?.message}
                                                            min={30}
                                                            ideal={40}
                                                            readMoreText="read more..."
                                                        />
                                                    ):''}
                                                </td>
                                                <td>{formatDateDMY(item.last_update_date)}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-6">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">USER DETAILS</h5>
                                <div className="card-body mb-3">
                                    <div>
                                        Providers: <span className="float-end">{userDashboardData?.providers}</span>
                                    </div>
                                    <hr/>
                                    <div>
                                        Practices: <span className="float-end">{userDashboardData?.practuces}</span>
                                    </div>
                                    <hr/>
                                    <div>
                                        Insurances: <span className="float-end">{userDashboardData?.total_insurances}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">FINANCIALS</h5>
                                <div className="card-body mb-3">
                                    <Link to="/invoice" className="text-reset text-decoration-none">
                                        <div>
                                            Total Amount: <span className="float-end">${userDashboardData?.payable_amount?.payable_amount || 0}</span>
                                        </div>
                                        <hr/>
                                        <div>
                                            Paid Amount: <span className="float-end">${userDashboardData?.paid_amount?.paid_amount || 0}</span>
                                        </div>
                                        <hr/>
                                        <div>
                                            Remaining Amount: <span className="float-end">${parseInt(userDashboardData?.payable_amount?.payable_amount) - parseInt(userDashboardData?.paid_amount?.paid_amount) || 0}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-4">*/}
                        {/*    <div className="card radius-15">*/}
                        {/*        <h5 className="card-header card-header-style text-white">DOCUMENT STATS</h5>*/}
                        {/*        <div className="card-body mb-3">*/}
                        {/*            <div>*/}
                        {/*                Total: <span className="float-end">0</span>*/}
                        {/*            </div>*/}
                        {/*            <hr/>*/}
                        {/*            <div>*/}
                        {/*                Approved: <span className="float-end">0</span>*/}
                        {/*            </div>*/}
                        {/*            <hr/>*/}
                        {/*            <div>*/}
                        {/*                Rejected: <span className="float-end">0</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;