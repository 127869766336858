import React, {useContext, useEffect, useState} from 'react';
import {bgImgFunc, formatDateDMY} from "../general/utils";
import {CustomPagination} from "../general/CustomPagination";
import {DocContext} from "./context/DocContext";
import './contract.css';
import {HeaderTitle} from "../general/HeaderTitle";
import {Loader} from "../general/Loader";
import {NotFound} from "../general/NotFound";
const {REACT_APP_BACKEND_URL} = process.env;
const DocList = () => {
    const {isLoading, docList} = useContext(DocContext)
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 8;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = docList?.slice(indexOfFirstRecord, indexOfLastRecord) || [];

    // setSearchList(currentRecords);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="container-fluid contractWrapper">
                <div className="row mx-0">
                    <div className="col-12">
                        <HeaderTitle title="Contracts" />
                    </div>
                </div>
                <>
                    <div className="row my-4">
                        <div className="col-12">
                            <div className="">
                                <table className="table bg-transparent customTbl">
                                    <thead className="card-header-style text-white fw-semibold fs-8 text-white headerBgImg text-uppercase align-middle align-items-center">
                                    <tr>
                                        <th className="text-white ps-4">SR NO.</th>
                                        <th className="text-white">Contract Name</th>
                                        <th className="text-white">Date</th>
                                        <th className="text-white">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8">
                                    {currentRecords.length > 0 ? currentRecords.map((item, index) => (
                                        <tr key={index} className="align-middle align-items-center data-table-row bg-gray">
                                            <td>{index+1}</td>
                                            <td>{item.title}</td>
                                            <td>{formatDateDMY(item.added_on)}</td>
                                            <td>
                                                {item.docs_files.split(',').map((fileName, index1) => (
                                                    <>
                                                        <a
                                                            key={`a${index}`}
                                                            href={`${REACT_APP_BACKEND_URL}public/credential_images/${fileName.trim()}`}
                                                            target="_blank" className="btn btn-sm me-3 btn-primary contractBtn align-middle align-items-center">
                                                            Download
                                                        </a>
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="4">
                                                <NotFound message="No data found" />
                                            </td>
                                        </tr>

                                    )}
                                    </tbody>
                                </table>
                                <CustomPagination
                                    totalRecords={docList?.length || 0}
                                    recordsPerPage={recordsPerPage}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default DocList;
