import React, {useState , useEffect , useRef} from 'react';
import Sidebar from "./Sidebar";
import {Outlet, useLocation} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import "./responsive.css";

const LayoutWrapper = () => {
    const {pathname} = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);
    let handleOpen = () => {
        setShowSidebar(!showSidebar);
    }

    useEffect(() => {
        setShowSidebar(false);
    }, [pathname]);

    return (
        <div className="admin-layout">
            <div className="container-fluid">
                <Row>
                    <Col md={2} >
                        <Sidebar show={showSidebar} />
                    </Col>
                    <Col md={10} >
                        <div className="nav_mobile d-flex justify-content-between d-md-none">
                            <img alt='MenuIcon' onClick={handleOpen}   src={'/menu_bar.svg'} style={{ width: '25px', marginRight: '7px' , marginTop: '7px' }} className='float-endr'/>
                            <img alt='Logo' src={process.env.PUBLIC_URL + '/logo.png'} style={{ width: '120px', marginTop: '7px', objectFit: 'contain' }} className=''/>
                        </div>
                        <div className="admin-content text-start px-md-3 m-md-3 mt-5">
                            <Outlet />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default LayoutWrapper;
