import React, {useContext, useEffect, useState} from 'react';
import {useLocation, Link, useParams} from "react-router-dom";
import {formatDateDMY} from "../../general/utils";
import {Button, Col, Row} from "react-bootstrap";
import {AppContext} from "../context/AppContext";
import {HeaderTitle} from "../../general/HeaderTitle";
import {KTSVG} from "../../general/KTSVG";
import {v4 as uuidv4} from "uuid";
const AppDetails = () => {
    const {app_id} = useParams();
    const {updateInfo} = useContext(AppContext);
    const location = useLocation();
    const [timelines, setTimelines] = useState([]);
    const [application, setApplication] = useState(null);
    const [appStatus, setAppStatus] = useState(null);
    const [appType, setAppType] = useState(null);
    const [showPractice, setShowPractice] = useState('d-none');
    const [showProvider, setShowProvider] = useState('d-none');
    const [serviceAddresses, setServiceAddresses] = useState([]);

    const initialFormData = {
        credential_id:'',
        form_type:'',
        group_name:'',
        group_npi:'',
        group_tax_id:'',
        owner_dob:'',
        provider_name:'',
        provider_npi:'',
        ssn_number:'',
        service_address:'',
        billing_mailing_address:'',
        specialty:'',
        license_number:'',
        home_address:'',
        mailing_address: '',
        practice_phone: '',
        practice_fax: '',
        email: '',
        business_hours: '',
    };
    const [form, setForm] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const handleSaveData = () => {
        let formData = new FormData();
        formData.append('app_id', app_id);
        formData.append('credential_id', location.state.item.credential?.credential_id);
        formData.append('form_type', location.state.item.credential?.form_type);
        formData.append('group_name', form.group_name);
        formData.append('group_npi', form.group_npi);
        formData.append('group_tax_id', form.group_tax_id);
        formData.append('owner_dob', form.owner_dob);
        formData.append('provider_name', form.provider_name);
        formData.append('provider_npi', form.provider_npi);
        formData.append('ssn_number', form.ssn_number);
        formData.append('service_address', JSON.stringify(serviceAddresses.map(address => address.value)));
        formData.append('billing_mailing_address', form.billing_mailing_address);
        formData.append('specialty', form.specialty);
        formData.append('license_number', form.license_number);
        formData.append('home_address', form.home_address);
        formData.append('mailing_address', form.mailing_address);
        formData.append('practice_phone', form.practice_phone);
        formData.append('practice_fax', form.practice_fax);
        formData.append('email', form.email);
        formData.append('business_hours', form.business_hours);

        const validationErrors = {};
        if (!form.specialty) {
            validationErrors.specialty = 'Specialty is Required!';
        }

        if (Object.keys(validationErrors).length === 0) {
            //updateInfo(formData);
            setErrors({});
        }else {
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        setTimelines(location.state.item.insurance_timeline)
        if(location.state.item.credential?.form_type === 'credentialing_individual_provider'){
            setAppType('Provider');
            setApplication(location.state.item.credential?.provider_name+' ('+location.state.item.insurance?.title+')')
            setShowPractice('d-none');
            setShowProvider('d-block');
        } else {
            setAppType('Practice')
            setApplication(location.state.item.credential?.group_name+' ('+location.state.item.insurance?.title+')')
            setShowPractice('d-block');
            setShowProvider('d-none');
        }
        setAppStatus(location.state.item?.insurance_status);

        const parsedServiceAddresses = JSON.parse(location.state.item.credential?.service_address || '[]');
        setServiceAddresses(
            parsedServiceAddresses.length > 0
                ? parsedServiceAddresses.map((address, index) => ({ id: uuidv4(), value: address }))
                : [{ id: uuidv4(), value: "" }]
        );
        setForm({
            credential_id:location.state.item.credential?.credential_id,
            form_type:location.state.item.credential?.form_type,
            group_name:location.state.item.credential?.group_name,
            group_npi:location.state.item.credential?.group_npi,
            group_tax_id:location.state.item.credential?.group_tax_id,
            owner_dob:location.state.item.credential?.owner_dob,
            provider_name:location.state.item.credential?.provider_name,
            provider_npi:location.state.item.credential?.provider_npi,
            ssn_number:location.state.item.credential?.ssn_number,
            service_address:location.state.item.credential?.service_address,
            billing_mailing_address:location.state.item.credential?.billing_mailing_address,
            specialty:location.state.item.credential?.specialty,
            license_number:location.state.item.credential?.license_number,
            home_address:location.state.item.credential?.home_address,
            mailing_address: location.state.item.credential?.mailing_address,
            practice_phone: location.state.item.credential?.practice_phone,
            practice_fax: location.state.item.credential?.practice_fax,
            email: location.state.item.credential?.email,
            business_hours: location.state.item.credential?.business_hours,
        })
    }, [location.state.item]);
    const addServiceAddresses = () => {
        setServiceAddresses((prevAddresses) => [...prevAddresses, { id: uuidv4(), value: '' }]);
    };
    const handleServiceAddress = (index, value) => {
        setServiceAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses[index].value = value;
            setForm((prevForm) => ({
                ...prevForm,
                service_address: updatedAddresses.map((address) => address.value),
            }));
            return updatedAddresses;
        });
    };
    const removeServicesAddress = (index) => {
        setServiceAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses.splice(index, 1);
            return updatedAddresses;
        });
    };

    return (
        <div className="container-fluid">
            <div className="row mx-0">
                <div className="col-12">
                    <Link to={{ pathname: '/application' }} ></Link>
                    <HeaderTitle title={application} />
                </div>
            </div>
            <>
                <div className="row mt-4">
                    <div className="col-12 col-md-7">
                        <div className="card radius-15 bg-gray">
                            <div className="border-bottom-0 card-header">
                                <h5 className="header-text">Application Timeline:</h5>
                                <div><span className="dot me-2 mt-1 position-absolute"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {appStatus}</div>
                            </div>
                            <div className="card-body">
                                <div className="activity-feed">
                                    {timelines?.map((item, index) => (
                                        <div key={index} className="feed-item">
                                            <div className="date">{formatDateDMY(item.created_at)}</div>
                                            <div className="text">{item.message}</div>
                                        </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 mt-4 mt-md-0">
                        <div className="card radius-15 bg-gray">
                            <div className="border-bottom-0 card-header">
                                <h5 className="header-text">{appType} Information:</h5>
                            </div>
                            <div className="card-body">
                                <Row>
                                    <Col md={12} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='group_name'>Group Name</label>
                                            <input
                                                id='group_name'
                                                disabled
                                                className={`form-control ${errors.group_name ? 'is-invalid' : ''}`}
                                                value={form.group_name}
                                                onChange={(e) => setForm({ ...form, group_name: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} className={showProvider}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='provider_name'>Provider Name</label>
                                            <input
                                                id='provider_name'
                                                disabled
                                                className={`form-control ${errors.provider_name ? 'is-invalid' : ''}`}
                                                value={form.provider_name}
                                                onChange={(e) => setForm({ ...form, provider_name: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='group_npi'>Group NPI</label>
                                            <input
                                                id='group_npi'
                                                disabled
                                                className={`form-control ${errors.group_npi ? 'is-invalid' : ''}`}
                                                value={form.group_npi}
                                                onChange={(e) => setForm({ ...form, group_npi: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showProvider}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='provider_npi'>Provider NPI</label>
                                            <input
                                                id='provider_npi'
                                                disabled
                                                className={`form-control ${errors.provider_npi ? 'is-invalid' : ''}`}
                                                value={form.provider_npi}
                                                onChange={(e) => setForm({ ...form, provider_npi: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='specialty'>Specialty</label>
                                            <input
                                                id='specialty'
                                                disabled
                                                className='form-control'
                                                value={form.specialty}
                                                onChange={(e) => setForm({ ...form, specialty: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='practice_phone'>Practice Phone</label>
                                            <input
                                                id='practice_phone'
                                                disabled
                                                className={`form-control ${errors.practice_phone ? 'is-invalid' : ''}`}
                                                value={form.practice_phone}
                                                onChange={(e) => setForm({ ...form, practice_phone: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='practice_fax'>Practice Fax</label>
                                            <input
                                                id='practice_fax'
                                                disabled
                                                className={`form-control ${errors.practice_fax ? 'is-invalid' : ''}`}
                                                value={form.practice_fax}
                                                onChange={(e) => setForm({ ...form, practice_fax: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='email'>Email</label>
                                            <input
                                                id='email'
                                                disabled
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                value={form.email}
                                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='business_hours'>Business Hours</label>
                                            <input
                                                id='business_hours'
                                                disabled
                                                className={`form-control ${errors.business_hours ? 'is-invalid' : ''}`}
                                                value={form.business_hours}
                                                onChange={(e) => setForm({ ...form, business_hours: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='billing_mailing_address'>Billing Address</label>
                                            <input
                                                id='billing_mailing_address'
                                                disabled
                                                className='form-control'
                                                value={form.billing_mailing_address}
                                                onChange={(e) => setForm({ ...form, billing_mailing_address: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='mailing_address'>Mailing Address</label>
                                            <input
                                                id='mailing_address'
                                                disabled
                                                className='form-control'
                                                value={form.mailing_address}
                                                onChange={(e) => setForm({ ...form, mailing_address: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showPractice}>
                                        <div className='form-group mb-3'>
                                            <label htmlFor='service_address'>Service Address</label>
                                            {serviceAddresses.map((address, index) => (
                                                <div key={address.id} className='mb-3 input-group'>
                                                    <input
                                                        id={`service_address_${index}`}
                                                        type='text'
                                                        name={`service_address_${index}`}
                                                        disabled
                                                        className='form-control'
                                                        value={address.value}
                                                        placeholder={`Service Address ${index + 1}`}
                                                        onChange={(e) => handleServiceAddress(index, e.target.value)}
                                                    />
                                                    {index === 0 && (
                                                        <div className="input-group-append d-none">
                                                            <button
                                                                className="btn bg-transparent btn-sm"
                                                                title="Add More"
                                                                type="button"
                                                                onClick={addServiceAddresses}
                                                            >
                                                                <KTSVG path="/svg/addmore.svg" />
                                                            </button>
                                                        </div>
                                                    )}
                                                    {index > 0 && (
                                                        <div className="input-group-append d-none">
                                                            <button
                                                                className="btn btn-transparent btn-sm"
                                                                title="Remove"
                                                                type="button"
                                                                onClick={() => removeServicesAddress(index)}
                                                            >
                                                                <KTSVG path="/svg/delete.svg" />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    {/*<Col md={6} className={showPractice}>*/}
                                    {/*    <div className='form-group  mb-3'>*/}
                                    {/*        <label htmlFor='service_address'>Service Address</label>*/}
                                    {/*        <input*/}
                                    {/*            id='service_address'*/}
                                     {/*       className='form-control'*/}
                                    {/*            value={form.service_address}*/}
                                    {/*            onChange={(e) => setForm({ ...form, service_address: e.target.value })}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    <Col md={6} className={showProvider}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='owner_dob'>DOB</label>
                                            <input
                                                id='owner_dob'
                                                type='date'
                                                disabled
                                                className={`form-control ${errors.owner_dob ? 'is-invalid' : ''}`}
                                                value={form.owner_dob}
                                                onChange={(e) => setForm({ ...form, owner_dob: e.target.value })}
                                            />
                                            {errors.owner_dob && <span className="invalid-feedback">{errors.owner_dob}</span>}
                                        </div>
                                    </Col>
                                    <Col md={6} className={showProvider}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='ssn_number'>SSN Number</label>
                                            <input
                                                id='ssn_number'
                                                disabled
                                                className='form-control'
                                                value={form.ssn_number}
                                                onChange={(e) => setForm({ ...form, ssn_number: e.target.value })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={6} className={showProvider}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='license_number'>License Number</label>
                                            <input
                                                id='license_number'
                                                disabled
                                                className='form-control'
                                                value={form.license_number}
                                                onChange={(e) => setForm({ ...form, license_number: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className={showProvider}>
                                        <div className='form-group  mb-3'>
                                            <label htmlFor='home_address'>Home Address</label>
                                            <input
                                                id='home_address'
                                                disabled
                                                className='form-control'
                                                value={form.home_address}
                                                onChange={(e) => setForm({ ...form, home_address: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end">
                                    <Button variant="primary btn-sm d-none" >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default AppDetails;
