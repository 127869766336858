import React, { useContext, useEffect, useState } from "react";
import { InvoiceContext } from "./context/InoviceContext";
import { NotFound } from "../general/NotFound";
import { Loader } from "../general/Loader";
import { HeaderTitle } from "../general/HeaderTitle";
import { formatDateDMY } from "../general/utils";
import { InvoiceViewModal } from "./modal/InvoiceViewModal";
import { PayNowModal } from "./modal/PayNowModal";

export const Invoice = () => {
    const { isLoading, accountStatements } = useContext(InvoiceContext);
    const invoiceList = accountStatements?.invoices;
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showPayNow, setShowPayNow] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);
    //for maintenance mode
    const [invoicePaid, setInvoicePaid] = useState(false);
    let totalAmount = 0;
    let paidAmount = 0;
    const isMobileView = window.innerWidth <= 600;

    const handleShowInvoice = (i) => {
        setSelectedInvoice(invoiceList[i]);
        setShowInvoice(true);
        //for maintenance mode
        const due = invoiceList[i].total_amount - (invoiceList[i].paid_amount ? invoiceList[i].paid_amount : 0);
        setInvoicePaid(due === 0);
    };

    const showStatus = (paid, due) => {
        if (paid == 0 && due > 0) {
            return "Pending";
        } else if (due == 0) {
            return "Paid";
        } else {
            return "Partial";
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="row mx-0 mt-3 mb-5">
                        <div className="col-12 bgGray radius-5">
                            <HeaderTitle title="Invoices" />
                        </div>
                    </div>
                    <div className="container-xxl removePd px-0">
                        <div className="financeStatement financeInvoice mt-10">
                            <table
                                id="kt_datatable_zero_configuration"
                                className="table table-row-bordered gy-5 table-hover invoiceTbl"
                            >
                                <thead>
                                <tr className="fw-semibold fs-8 text-white text-center headerBgImg text-uppercase align-middle align-items-center">
                                    <th>INVOICE NO.</th>
                                    <th>AMOUNT</th>
                                    <th className="mobileHide">DISCOUNT</th>
                                    <th>DUE DATE</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody className="fs-8 text-center bgGray">
                                {invoiceList?.length === 0 || invoiceList === null ? (
                                    <tr>
                                        <td colSpan="7">
                                            <NotFound message="No Account Statement Available" />
                                        </td>
                                    </tr>
                                ) : (
                                    invoiceList?.map((list, index) => {
                                        totalAmount += parseFloat(list.total_amount);
                                        const due = list.total_amount - (list.paid_amount ? list.paid_amount : 0);
                                        const paid = parseFloat(list.paid_amount ? list.paid_amount : 0);
                                        paidAmount += paid;

                                        return (
                                            <tr
                                                onClick={() => handleShowInvoice(index)}
                                                key={index}
                                                className="align-middle align-items-center data-table-row bg-gray cursor-pointer"
                                                title="Invoice Pay Now"
                                            >
                                                <td>INV-{list.invoice_id}</td>
                                                <td>{paid === 0 ? list.total_amount : '-'}</td>
                                                <td className="mobileHide">{list?.discount}</td>
                                                <td>{formatDateDMY(list.due_date)}</td>
                                                <td>
                                                    <button
                                                        className={`btn btn-sm invoiceStatusBtn me-2 ${
                                                            due === 0 ? 'invoicePaid' : 'invoicePending'
                                                        }`}
                                                    >
                                                        {showStatus(paid, due)}
                                                    </button>
                                                    <span>
                                                            <img src="/img/arrow-left.png" alt="arrow left" />
                                                        </span>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                                </tbody>
                            </table>
                            {showInvoice && <InvoiceViewModal payNow={()=>{setShowPayNow(true);setShowInvoice(false);}} invoice={selectedInvoice} show={showInvoice} onClose={()=>{setShowInvoice(false);}}/>}
                            {showPayNow && <PayNowModal invoice={selectedInvoice} show={showPayNow} onClose={()=>{setShowPayNow(false)}}/> }
                            {/*{showInvoice && <PaymentMaintenanceMode invoice={selectedInvoice} show={showInvoice} onClose={()=>{setShowInvoice(false)}}/> }*/}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
