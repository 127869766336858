import {createContext, useEffect, useState} from "react"
import {getUserDashboardReq,} from "./_request"
export const DashboardContext = createContext();
export const DashboardContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [userDashboardData, setUserDashboardData] = useState([]);

    useEffect(() => {
        getUserDashboard();
    }, []);

    const getUserDashboard = async () => {
        setLoading(true);
        let data = await getUserDashboardReq();
        setUserDashboardData(data);
        setLoading(false);
    };


    return (
        <DashboardContext.Provider value={{
            loading,
            userDashboardData,
            getUserDashboard,
        }}>
            {children}
        </DashboardContext.Provider>
    )
}
