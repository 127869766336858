import {createContext, useEffect, useState} from "react"
import {getAppListReq, updateInfoReq} from "./_request"
import {useNavigate, useParams} from "react-router-dom";
import {customAlert} from "../../general/helpers";
export const AppContext = createContext()
export const AppContextProvider = ({children}) => {
    const navigate = useNavigate();
    const {app_id} = useParams();

    const [loading, setLoading] = useState(false)
    const [appData, setAppData] = useState([])

    useEffect(() => {
        getAppList()
    }, [])

    const getAppList = async () => {
        setLoading(true);
        let response = await getAppListReq();
        if(response.status.toLowerCase() === 'success'){
            setAppData(response.app_list);
            setLoading(false);
            return response.app_list;
        }else {
            setLoading(false);
            return [];
        }

    };
    const updateInfo = async (data) => {
        setLoading(true)
        const resp = await updateInfoReq(data);
        if(resp.status.toLowerCase() === 'success'){
            customAlert('Updated Successfully', 'success');
            navigate('/application/'+app_id, { state: { item: resp.item } });
        }else {
            customAlert('Something went wrong', 'error');
        }
        setLoading(false)
    };

    return (
        <AppContext.Provider value={{
            isLoading: loading,
            appData,
            getAppList,
            setLoading,
            updateInfo,
        }}>
            {children}
        </AppContext.Provider>
    )
}
