import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_APP_LIST_URL = REACT_APP_BACKEND_API_URL+"get_app_list"
const UPDATE_INFO_URL = REACT_APP_BACKEND_API_URL+"update_app_info"

let getAppListReq = async () => {
    try {
        const d = await axios
            .get(`${GET_APP_LIST_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in get App List Req() : ", error);
        throw error;
    }
}
let updateInfoReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_INFO_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in update Persona lInfo Req() : ", error);
        throw error;
    }
}
export {getAppListReq, updateInfoReq}