import {Button, Col, Modal, Row} from "react-bootstrap";
import {formatDateDMY} from "../../general/utils";
import React, {useState} from "react";
import {AgreementModal} from "./AgreementModal";

export const InvoiceViewModal = ({show, onClose, invoice, payNow}) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showAgreement, setShowAgreement] = useState(false);
    let totalPaid = 0;
    const handleAgreement = () => {
        setShowAgreement(true);
    }

    return (
        <Modal show={show} onHide={onClose} size="lg" className="customModalClass"  backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="bg-primary py-2 px-6">
                <Modal.Title className="text-white">Invoice Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="invoiceModal">
                <>
                    <Row>
                        <Col md={6}>
                            <img alt='Logo' style={{width:160}} src={process.env.PUBLIC_URL + '/logo.png'} className='p-3'/>
                            <h5 className='mt-3 mb-3 ps-3'>{invoice.title}</h5>
                        </Col>
                        <Col md={6} className="text-end pe-4">
                            <h5>INVOICE:</h5>
                            <h5># INV-{invoice?.invoice_id}</h5>
                            <p>Due Date: {formatDateDMY(invoice?.due_date)}</p>
                        </Col>
                        <Col md={12} className="invoiceTblContainer px-4 py-1">
                            { (invoice && invoice?.invoice_details?.length > 0) && (
                                <table className="table bg-transparent customTbl border-0 rounder-0">
                                    <thead>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>QTY</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        invoice ? invoice.invoice_details.map((item, index)=>{
                                            let title = "";
                                            if (item.type == 'service') {
                                                title = item.description;
                                            } else {
                                                let group_name = item.group_name == null || item.group_name == "null" ? "" : item.group_name
                                                let provider_name = item.provider_name == null || item.provider_name == "null" ? "" : item.provider_name
                                                title = group_name + " " + provider_name + " " + item.title
                                            }
                                            return (
                                                <tr key={index} className='invoiceDetailRow align-items-center align-middle'>
                                                    <td className="ps-3">{(index+1)}</td>
                                                    <td>{title}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.amount}</td>
                                                </tr>
                                            )
                                        }) : null
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr className='invoiceDetailRow'>
                                        <th colSpan={3} className="text-end"></th>
                                        <th className="invoiceFooter text-center">Total:</th>
                                        <th className="invoiceFooter">{invoice.total_amount+invoice?.discount}</th>
                                    </tr>
                                    <tr className='invoiceDetailRow borderTopFooter'>
                                        <th colSpan={3} className="text-end"></th>
                                        <th className="invoiceFooter text-center">Discount:</th>
                                        <th className="invoiceFooter">{invoice?.discount}</th>
                                    </tr>
                                    <tr className='invoiceDetailRow borderTopFooter'>
                                        <th colSpan={3} className="text-end"></th>
                                        <th className="invoiceFooter1 text-center">Due Amount:</th>
                                        <th className="invoiceFooter1">{invoice?.total_amount - totalPaid}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            )}
                            {
                                invoice?.receipts?.length > 0 ? (
                                    <table className="table bg-transparent customTbl border-0 rounder-0">
                                        <thead>
                                        <tr>
                                            <th className="fs-5" colSpan={4}>Receipts</th>
                                        </tr>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            invoice?.receipts.map((receipt, index) => {
                                                totalPaid+=parseFloat(receipt.total_amount)
                                                return (
                                                    <tr key={index} className='invoiceDetailRow align-items-center align-middle'>
                                                        <td className="ps-3">{index + 1}</td>
                                                        <td>{receipt.title}</td>
                                                        <td>{receipt.receipt_date}</td>
                                                        <td>{receipt.total_amount}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr className='invoiceDetailRow'>
                                            <th colSpan={2} className="text-end"></th>
                                            <th className="invoiceFooter text-center">Paid:</th>
                                            <th className="invoiceFooter">{totalPaid}</th>
                                        </tr>
                                        <tr className='invoiceDetailRow borderTopFooter'>
                                            <th colSpan={2} className="text-end"></th>
                                            <th className="invoiceFooter1 text-center">Due Amount:</th>
                                            <th className="invoiceFooter1">{invoice?.total_amount - totalPaid}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                ) : null
                            }
                            <table className="table bg-transparent customTbl border-0 rounder-0 d-none">
                                <thead>
                                <tr className='invoiceDetailRow'>
                                    <th colSpan={3} className="text-end"></th>
                                    <th className="text-end">Due Amount:</th>
                                    <th>{invoice?.total_amount - totalPaid}</th>
                                </tr>
                                </thead>
                            </table>
                        </Col>
                    </Row>
                    {(invoice?.total_amount - totalPaid) > 0 && (
                        <Row>
                            <Col md={12}>
                                <div className="text-start d-flex align-items-center">
                                    <input type="checkbox" name="terms" className="form-check-input ms-3 form-control termCheckBox border-primary" id="terms" onChange={(e)=>{setTermsAccepted(e.target.checked)}} checked={termsAccepted} />
                                    <p className="text-center pt-3 ps-3 f-w500">Check here to indicate that you have read and agree to the terms of <span className="textPrimary cursor-pointer" onClick={handleAgreement}>Credentialing Service Agreement.</span></p>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {showAgreement && <AgreementModal invoice={invoice} show={showAgreement} handleAcceptTerm={() => setTermsAccepted(true)} onClose={()=>{setShowAgreement(false)}}/> }
                </>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between invoiceModalFooter">
                <Button className="btn btn-primary border-0 fs10px" onClick={onClose}>CLOSE</Button>
                {
                    (invoice?.total_amount - totalPaid) > 0 ? (
                        <Button className="btn btn-primary fs10px px-3 py-2" disabled={!termsAccepted} onClick={payNow}>PROCEED TO CHECKOUT</Button>
                    ) : null
                }
            </Modal.Footer>
        </Modal>
    )
}
