import {createContext, useEffect, useState} from "react"
import {getUserInfoReq, updatePersonalInfoReq, addPracticesReq, addInsurancesReq, getPracticesReq, addInsurancesWithCredentialReq, getSelectedInsurancesReq, removeInsurancesReq} from "./_request"
import {customAlert} from "../../general/helpers";
export const UserContext = createContext()
export const UserContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState([])

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        setLoading(true)
        let data = await getUserInfoReq()
        setUserData(data)
        setLoading(false)
    };

    const updatePersonalInfo = async (data) => {
        setLoading(true);
        const response = await updatePersonalInfoReq(data);
        setUserData(response);
        setLoading(false);
        return response;
    }
    const updateLoginInfo = async (data) => {

    }
    const addPractices = async (data) => {
        setLoading(true)
        let resdata = await addPracticesReq(data)
        // setUserData(resdata)
        setLoading(false)
    }
    const addInsurances = async (data) => {
        setLoading(true)
        let resdata = await addInsurancesReq(data)
        setUserData(resdata.data.original)
        setLoading(false)
    }
    const getPractices = async (id) => {
        setLoading(true)
        let resdata = await getPracticesReq(id)
        setLoading(false)
        return resdata;
    }
    const addInsurancesWithCredential = async (data) => {
        return await addInsurancesWithCredentialReq(data);
    }
    const getSelectedInsurances = async (credential_id, user_id) => {
        setLoading(true);
        const response = await getSelectedInsurancesReq(credential_id, user_id);
        setLoading(false);
        return response;
    }
    const removeInsuranceData = async (data) => {
        setLoading(true);
        const response = await removeInsurancesReq(data);

        if(response?.status.toLowerCase() === 'success'){
            customAlert(response.result, 'success');
            setUserData(response?.data?.original);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <UserContext.Provider value={{
            isLoading: loading,
            loading,
            userData,
            getUserInfo,
            updatePersonalInfo,
            updateLoginInfo,
            addInsurances,
            addPractices,
            getPractices,
            addInsurancesWithCredential,
            getSelectedInsurances,
            removeInsuranceData
        }}>
            {children}
        </UserContext.Provider>
    )
}
