import {Modal, Row} from "react-bootstrap";
import React from "react";
import {PayNowForm} from "./compnents/PayNowForm";
import {StripeForm} from "./compnents/StripeForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export const PayNowModal = ({show, onClose, invoice}) => {
    let handleClose = () => {
        onClose()
    }
    return (
        <Modal show={show} onHide={handleClose} size="lg" className="customModalClass" backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="bg-primary py-2 px-6">
                <Modal.Title className="text-white">Payment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="invoiceBgGray">
                <Row>
                    <PayNowForm invoice={invoice}/>
                    {/*<Elements stripe={stripePromise} options={{clientSecret: invoice?.client_secret}}>*/}
                    {/*    <StripeForm invoice={invoice}/>*/}
                    {/*</Elements>*/}

                </Row>
            </Modal.Body>
        </Modal>
    )
}
