import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Button} from "react-bootstrap";
import {UploadDocumentContext} from "../context/UploadDocumentContext";
import {customAlert} from "../../general/helpers";
import Swal from "sweetalert2";
const {REACT_APP_BACKEND_URL} = process.env;
export const UploadDocument = ({userInfo, data, formType}) => {

    const { updateDocument, removeCustomerDocs } = useContext(UploadDocumentContext);
    const [files, setFiles] = useState()
    const intialValues = [
        { name: 'irs_letter_image', files: [] },
        { name: 'professional_liability_insurance_image', files: [] },
        { name: 'bank_letter_image', files: [] },
        { name: 'w9_form_image', files: [] },
        { name: 'state_license_image', files: [] },
        { name: 'driver_license_image', files: [] },
        { name: 'resume_image', files: [] },
        { name: 'degree_image', files: [] },
        { name: 'board_certification_image', files: [] },
        { name: 'accreditation_image', files: [] },
        { name: 'additional_document_image', files: [] },
    ]
    const [fileInputs, setFileInputs] = useState(intialValues);
    const handleFileChangeOld  = (event, inputName) => {
        const updatedInputs = fileInputs.map((input) =>
            input.name === inputName
                ? { ...input, files: [...event.target.files] }
                : input
        );
        setFileInputs(updatedInputs);
    };
    const [loading, setLoading] = useState(false);

    const handleFileChange = async (event, inputName) => {
        const files = event.target.files;
        const formData = new FormData();
        formData.append('credential_id', userInfo.credential_id);
        for (let i = 0; i < files.length; i++) {
            formData.append(`${inputName}[]`, files[i]);
        }

        try {
            setLoading(true);
            let filesnew = await updateDocument(formData);
            if (filesnew.status.toLowerCase() === 'success') {
                customAlert('Document Uploaded!', 'success');
                await handleFiles(filesnew);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error uploading files', error);
            setLoading(false);
        }
    };
    // const removeFileold = async (event, docType, fileName) => {
    //     event.preventDefault();
    //     try {
    //         setLoading(true);
    //         const response = await deleteDocument(userInfo.credential_id, docType, fileName);
    //         if (response.status.toLowerCase() === 'success') {
    //             customAlert('Document Deleted!', 'success');
    //             const updatedFiles = { ...files };
    //             updatedFiles[docType] = updatedFiles[docType].split(',').filter(item => item !== fileName).join(',');
    //             setFiles(updatedFiles);
    //         }
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error deleting file', error);
    //         setLoading(false);
    //     }
    // };
    const removeFile = async (e, colName, fileName) => {
        e.preventDefault();
        const confirmationMessage = await Swal.fire({
            title: "Are you sure?",
            text: "You can't revert this!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: '#1E6FE0',
            cancelButtonColor: '#dc3545',
            confirmButtonText: "Yes, Remove!",
            cancelButtonText: "Cancel",
            dangerMode: true,
        });
        if(confirmationMessage.isConfirmed){
            const formData = new FormData();
            formData.append('id', files?.id);
            formData.append('credential_id', files?.credential_id);
            formData.append('user_id', files?.user_id);
            formData.append('image_name', fileName);
            formData.append('col_name', colName);
            const response = await removeCustomerDocs(formData, files?.credential_id);
            console.log("response",response);
            if (response.status.toLowerCase() === 'success') {
                customAlert('Document Deleted!', 'success');
                const updatedFiles = { ...files };
                updatedFiles[colName] = updatedFiles[colName].split(',').filter(item => item !== fileName).join(',');
                setFiles(updatedFiles);
            }
        }
    }
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('credential_id', userInfo.credential_id);
            fileInputs.forEach((input) => {
                input.files.forEach((file) => {
                    formData.append(`${input.name}[]`, file);
                });
            });
            let filesnew = await updateDocument(formData);
            if(filesnew.status.toLowerCase() === 'success'){
                setLoading(false);
                customAlert('Document Added!', 'success');
            }
            await handleFiles(filesnew)
        } catch (error) {
            console.error('Error uploading files', error);
        }
    };
    const handleFiles = async(filesnew) =>{
        setFiles(filesnew.data)
        setFileInputs(intialValues)
    }
    const isFormEmpty = () => {
        return userInfo.credential_id === undefined;
    };
    const removeDocFile = (index, flag) => {
        const newFiles = [...fileInputs];
        newFiles.forEach((input) => {
            if(input.name === flag){
                input.files.splice(index, 1);
            }
        });
        setFileInputs(newFiles);
    }

    useEffect(() => {
        if(data) {
            setFiles(data)
        }
    }, [data]);

    return (
        <>
            <Row className="docWrapper">
                {loading && (
                    <div className="loadingOverLay">
                        <h4>Documents is Uploading. Please Wait</h4>
                    </div>
                )}
                <Col md={12}>
                    {formType==='Practice' && (
                        <div className='form-group mb-3'>
                            <label htmlFor="input_file_pics1" className="upld_prdct_label py-2 w-100">
                                IRS Letter
                                <input type="file" onChange={(event) => handleFileChange(event, 'irs_letter_image')} multiple id="input_file_pics1" className="input_profile_pic1 form-control" />
                                <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                            </label>
                            {/* new uploaded files*/}
                            {fileInputs && fileInputs[0].files.map((file, index) => (
                                <div key={index} className="file-container mt-2 mx-3">
                                    <span className="file-name">{file.name}</span>
                                    <button onClick={() => removeDocFile(index, 'irs_letter_image')} className="remove-button btn-sm">Remove</button>
                                </div>
                            ))}
                            {/* show old files*/}
                            {files && files.irs_letter_image && files.irs_letter_image.split(",")?.map((item, index) => (
                                <>
                                    <a
                                        key={index}
                                        className="m-1 mt-3 btn-sm btn downloadBtn"
                                        target="_blank"
                                        title="Download IRS Letter"
                                        href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                        download>
                                        Download File
                                    </a>
                                    <span onClick={(e) => removeFile(e, 'irs_letter_image', item)}
                                          className="shadow docRemove p-0"
                                          title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>

                                </>
                            ))}
                        </div>
                    )}
                </Col>
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics2" className="upld_prdct_label py-2 w-100">
                            {formType==='Practice' ? 'General Liability Insurance' : 'Professional Liability Insurance/ MalPractise'}
                            <input type="file" onChange={(event) => handleFileChange(event, 'professional_liability_insurance_image')} id="input_file_pics2" name="images2[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[1].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'professional_liability_insurance_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.professional_liability_insurance_image && files.professional_liability_insurance_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title={`Download ${formType==='Practice' ? 'General Liability Insurance' : 'Professional Liability Insurance/ MalPractise'}`}
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'professional_liability_insurance_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics3" className="upld_prdct_label py-2 w-100">
                            Bank Letter / Void Cheque
                            <input type="file" onChange={(event) => handleFileChange(event, 'bank_letter_image')} id="input_file_pics3" name="images3[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[2].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'bank_letter_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.bank_letter_image && files.bank_letter_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title="Download Bank Letter / Void Cheque"
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'bank_letter_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics4" className="upld_prdct_label py-2 w-100">
                            W9 Form
                            <input type="file" onChange={(event) => handleFileChange(event, 'w9_form_image')} id="input_file_pics4" name="images4[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[3].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'w9_form_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.w9_form_image && files.w9_form_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title="Download W9 Form"
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'w9_form_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics5" className="upld_prdct_label py-2 w-100">
                            {formType==='Practice' ? 'Business License' : 'State License'}
                            <input type="file" onChange={(event) => handleFileChange(event, 'state_license_image')} id="input_file_pics5" name="images5[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[4].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'state_license_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.state_license_image && files.state_license_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title={`Download ${formType==='Practice' ? 'Business License' : 'State License'}`}
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'state_license_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
                {formType==='Provider' && (
                    <>
                        <Col md={12}>
                            <div className='form-group mb-3'>
                                <label htmlFor="input_file_pics6" className="upld_prdct_label py-2 w-100">
                                    Driver License
                                    <input type="file" onChange={(event) => handleFileChange(event, 'driver_license_image')} id="input_file_pics6" name="images6[]" multiple className="input_profile_pic1 form-control" />
                                    <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                                </label>
                                {/* new uploaded files*/}
                                {fileInputs && fileInputs[5].files.map((file, index) => (
                                    <div key={index} className="file-container mt-2 mx-3">
                                        <span className="file-name">{file.name}</span>
                                        <button onClick={() => removeDocFile(index, 'driver_license_image')} className="remove-button btn-sm">Remove</button>
                                    </div>
                                ))}
                                {/* show old files*/}
                                {files && files.driver_license_image && files.driver_license_image.split(",")?.map((item, index) => (
                                    <>
                                    <a
                                        key={index}
                                        className="m-1 mt-3 btn-sm btn downloadBtn"
                                        target="_blank"
                                        title="Download Driver License"
                                        href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                        download>
                                        Download File
                                    </a>
                                    <span onClick={(e) => removeFile(e, 'driver_license_image', item)}
                                className="shadow docRemove p-0"
                                title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                        </>
                                ))}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className='form-group mb-3'>
                                <label htmlFor="input_file_pics7" className="upld_prdct_label py-2 w-100">
                                    Resume
                                    <input type="file" onChange={(event) => handleFileChange(event, 'resume_image')} id="input_file_pics7" name="images7[]" multiple className="input_profile_pic1 form-control" />
                                    <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                                </label>
                                {/* new uploaded files*/}
                                {fileInputs && fileInputs[6].files.map((file, index) => (
                                    <div key={index} className="file-container mt-2 mx-3">
                                        <span className="file-name">{file.name}</span>
                                        <button onClick={() => removeDocFile(index, 'resume_image')} className="remove-button btn-sm">Remove</button>
                                    </div>
                                ))}
                                {/* show old files*/}
                                {files && files.resume_image && files.resume_image.split(",")?.map((item, index) => (
                                    <>
                                    <a
                                        key={index}
                                        className="m-1 mt-3 btn-sm btn downloadBtn"
                                        target="_blank"
                                        title="Download Resume"
                                        href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                        download>
                                        Download File
                                    </a>
                                    <span onClick={(e) => removeFile(e, 'resume_image', item)}
                                className="shadow docRemove p-0"
                                title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                        </>
                                ))}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className='form-group mb-3'>
                                <label htmlFor="input_file_pics8" className="upld_prdct_label py-2 w-100">
                                    Degree
                                    <input type="file" onChange={(event) => handleFileChange(event, 'degree_image')} id="input_file_pics8" name="images8[]" multiple className="input_profile_pic1 form-control" />
                                    <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                                </label>
                                {/* new uploaded files*/}
                                {fileInputs && fileInputs[7].files.map((file, index) => (
                                    <div key={index} className="file-container mt-2 mx-3">
                                        <span className="file-name">{file.name}</span>
                                        <button onClick={() => removeDocFile(index, 'degree_image')} className="remove-button btn-sm">Remove</button>
                                    </div>
                                ))}
                                {/* show old files*/}
                                {files && files.degree_image && files.degree_image.split(",")?.map((item, index) => (
                                   <>
                                    <a
                                        key={index}
                                        className="m-1 mt-3 btn-sm btn downloadBtn"
                                        target="_blank"
                                        title="Download Degree"
                                        href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                        download>
                                        Download File
                                    </a>
                                    <span onClick={(e) => removeFile(e, 'degree_image', item)}
                                className="shadow docRemove p-0"
                                title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                        </>
                                ))}
                            </div>
                        </Col>
                    </>)}
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics9" className="upld_prdct_label py-2 w-100">
                            Board Certification {formType === 'Practice' ? '/ Accreditation' : ''}
                            <input type="file" onChange={(event) => handleFileChange(event, 'board_certification_image')} id="input_file_pics9" name="images9[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[8].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'board_certification_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.board_certification_image && files.board_certification_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title="Download Board Certification or Accreditation"
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'board_certification_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
                {/*<Col md={12}>*/}
                {/*    <div className='form-group mb-3'>*/}
                {/*        <label htmlFor="input_file_pics10" className="upld_prdct_label py-2 w-100">*/}
                {/*            Accreditation*/}
                {/*            <input type="file" onChange={(event) => handleFileChange(event, 'accreditation_image')} id="input_file_pics10" name="images9[]" multiple className="input_profile_pic1 form-control" />*/}
                {/*            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>*/}
                {/*        </label>*/}
                {/*        {fileInputs && fileInputs[9].files.map((file, index) => (*/}
                {/*            <div key={index} className="file-container mt-2 mx-3">*/}
                {/*                <span className="file-name">{file.name}</span>*/}
                {/*                <button onClick={() => removeDocFile(index, 'accreditation_image')} className="remove-button btn-sm">Remove</button>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*        {files && files.accreditation_image && files.accreditation_image.split(",")?.map((item, index) => (*/}
                {/*            <a*/}
                {/*                key={index}*/}
                {/*                className="m-1 mt-3 btn-sm btn downloadBtn"*/}
                {/*                target="_blank"*/}
                {/*                title="Download Accreditation"*/}
                {/*                href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}*/}
                {/*                download>*/}
                {/*                Download File*/}
                {/*            </a>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col md={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="input_file_pics11" className="upld_prdct_label py-2 w-100">
                            Any Additional Documents for your Specialty
                            <input type="file" onChange={(event) => handleFileChange(event, 'additional_document_image')} id="input_file_pics11" name="images11[]" multiple className="input_profile_pic1 form-control" />
                            <span className="float-end choseFileBtn"><img alt='Logo' src={process.env.PUBLIC_URL + '/choosefile.png'} style={{ width: '12px', marginRight: '7px' }} className='float-endr'/>Choose File</span>
                        </label>
                        {/* new uploaded files*/}
                        {fileInputs && fileInputs[10].files.map((file, index) => (
                            <div key={index} className="file-container mt-2 mx-3">
                                <span className="file-name">{file.name}</span>
                                <button onClick={() => removeDocFile(index, 'additional_document_image')} className="remove-button btn-sm">Remove</button>
                            </div>
                        ))}
                        {/* show old files*/}
                        {files && files.additional_document_image && files.additional_document_image.split(",")?.map((item, index) => (
                            <>
                                <a
                                    key={index}
                                    className="m-1 mt-3 btn-sm btn downloadBtn"
                                    target="_blank"
                                    title="Download Any Additional Documents for your Specialty"
                                    href={REACT_APP_BACKEND_URL + 'public/credential_images/' + item}
                                    download>
                                    Download File
                                </a>
                                <span onClick={(e) => removeFile(e, 'additional_document_image', item)}
                                      className="shadow docRemove p-0"
                                      title="remove"><i className="fa fa-outline fa-times fs-9 text-danger background-white"></i></span>
                            </>
                        ))}
                    </div>
                </Col>
            </Row>
            <div className="text-end mb-4">
                <span className="d-inline-block">
                     { isFormEmpty() && <span className="text-danger me-3">Please fill the required fields for {userInfo?.form_type === 'credentialing_agencies' ? 'Practice' : 'Provider'} Details </span> }
                    <Button variant="transparent btn-sm px-5 fs-5 me-3 mt-2" disabled={loading || isFormEmpty()}>
                        {loading ? 'Uploading...' : ''}
                    </Button>
                </span>
            </div>
        </>
    )
}
