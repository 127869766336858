import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_DOC_INO_URL = REACT_APP_BACKEND_API_URL+"get_doc_list"

let getDocInfoReq = async () => {
    try {
        const d = await axios
            .get(`${GET_DOC_INO_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in get Doc Info Req() : ", error);
        throw error;
    }
}

export {getDocInfoReq}