import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {ProfileContext} from "./context/ProfileContext";
import {HeaderTitle} from "../general/HeaderTitle";
const Profile = () => {
    const {loading, userInfo, resetPassword, addCardDetail} = useContext(ProfileContext)
    const initialFormData = {
        current_password:'',
        new_password:'',
        confirm_password:'',
    };
    const initialPaymentFormData = {
        bank_name:'',
        full_name:'',
        card_number:'',
        exp_date:'',
        phone_number:'',
        cvv:'',
        email:'',
        country:'',
        address:'',
        city:'',
        state:'',
        zip:'',
    };
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [form, setForm] = useState(initialFormData);
    const [paymentForm, setPaymentForm] = useState(initialPaymentFormData);
    const [errors, setErrors] = useState({});
    const handleSaveData = async () => {
        let formData = new FormData();
        formData.append('current_password', form.current_password);
        formData.append('new_password', form.new_password);
        formData.append('confirm_password', form.confirm_password);

        const validationErrors = {};
        if (!form.current_password) {
            validationErrors.current_password = 'Current Password is Required!';
        }
        if (!form.new_password) {
            validationErrors.new_password = 'New Password is Required!';
        }
        if (!form.confirm_password) {
            validationErrors.confirm_password = 'Confirm Password is Required!';
        }
        if (form.new_password !== form.confirm_password) {
            validationErrors.confirm_password = 'Confirm Password Not Matched!';
        }
        if (Object.keys(validationErrors).length === 0) {
            let reset_resp = await resetPassword(formData);

            if(reset_resp.errors.current_password != null){
                validationErrors.current_password = reset_resp.errors.current_password;
                setErrors(validationErrors);
            }else {
                setErrors({});
            }
        }else {
            setErrors(validationErrors);
        }
    }
    const handleCardSaveData = async () => {
        let formData = new FormData();
        formData.append('bank_name', paymentForm.bank_name);
        formData.append('full_name', paymentForm.full_name);
        formData.append('card_number', paymentForm.card_number);
        formData.append('exp_date', paymentForm.exp_date);
        formData.append('phone_number', paymentForm.phone_number);
        formData.append('cvv', paymentForm.cvv);
        formData.append('email', paymentForm.email);
        formData.append('country', paymentForm.country);
        formData.append('address', paymentForm.address);
        formData.append('city', paymentForm.city);
        formData.append('state', paymentForm.state);
        formData.append('zip', paymentForm.zip);

        const validationErrors = {};
        if (!paymentForm.card_number) {
            validationErrors.card_number = 'Card Number is Required!';
        }
        if (!paymentForm.exp_date) {
            validationErrors.exp_date = 'Expiry Date is Required!';
        }
        if (!paymentForm.cvv) {
            validationErrors.cvv = 'CVV is Required!';
        }

        if (Object.keys(validationErrors).length === 0) {
            let reset_resp = await addCardDetail(formData)
            if(reset_resp.errors != null){
                setErrors(validationErrors);
            }else {
                setErrors({});
            }
        }else {
            setErrors(validationErrors);
        }
    }
    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };
    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const [activeTab, setActiveTab] = useState('tab1'); // Initial active tab state
    const handleTabClick = (tab) => {
        setActiveTab(tab); // Update active tab state when a tab is clicked
    };
    useEffect(() => {
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12">
                        <HeaderTitle title="Profile" />
                    </div>
                </div>

                <>
                    <div className="mx-3">
                        <div className="tab-buttons mt-3 d-none">
                            <button
                                className={`btn btn-info ${activeTab === 'tab1' ? 'active bg-success' : ''}`}
                                onClick={() => handleTabClick('tab1')}
                            >
                                Profile
                            </button>
                            <button
                                className={`btn btn-info ${activeTab === 'tab2' ? 'active bg-success' : ''}`}
                                onClick={() => handleTabClick('tab2')}
                            >
                                Payment
                            </button>
                        </div>
                        <div className="tab-content">
                            {activeTab === 'tab1' &&
                                <>
                                    <div className="row my-4">
                                        <div className="col-12 col-md-4 d-flex">
                                            <div className="card align-items-center radius-15 bg-gray w-100 pt-4 pb-2">
                                                <img height="150px" alt='Logo' src={process.env.PUBLIC_URL + '/profile.png'} className='p-3'/>
                                                <h4>{userInfo?.full_name}</h4>
                                                <p>{userInfo?.email}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-8 mt-4 mt-md-0 d-flex">
                                            <div className="card radius-15 bg-gray w-100 p-3 ps-4">
                                                {loading && (
                                                    <div className="loadingOverLay">
                                                        <h4>Processing!. Please Wait...!</h4>
                                                    </div>
                                                )}
                                                <h5 className="header-text">Reset your Password</h5>
                                                {
                                                    !errors.current_password &&
                                                    !errors.new_password &&
                                                    !errors.confirm_password &&
                                                    form.confirm_password.confirm_password != null &&
                                                    <h6 className="text-success">Password Change Successfully!</h6>
                                                }
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='current_password' className="f-w600 mb-2">Current Password</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='current_password'
                                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                                    className={`custom-input-pass border-0 w-100 ${errors.current_password ? 'is-invalid' : ''}`}
                                                                    value={form.current_password}
                                                                    onChange={(e) => setForm({ ...form, current_password: e.target.value })}
                                                                />
                                                                <i className={showCurrentPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={toggleShowCurrentPassword}>
                                                                </i>
                                                            </div>
                                                            {errors.current_password && <span className="invalid-feedback d-block">{errors.current_password}</span>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='new_password' className="f-w600 mb-2">New Password</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='new_password'
                                                                    type={showNewPassword ? 'text' : 'password'}
                                                                    className={`custom-input-pass border-0 w-100 ${errors.new_password ? 'is-invalid' : ''}`}
                                                                    value={form.new_password}
                                                                    onChange={(e) => setForm({ ...form, new_password: e.target.value })}
                                                                />
                                                                <i className={showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={toggleShowNewPassword}>
                                                                </i>
                                                            </div>
                                                            {errors.new_password && <span className="invalid-feedback d-block">{errors.new_password}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='confirm_password' className="f-w600 mb-2">Confirm New Password</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='confirm_password'
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                    className={`custom-input-pass border-0 w-100 ${errors.confirm_password ? 'is-invalid' : ''}`}
                                                                    value={form.confirm_password}
                                                                    onChange={(e) => setForm({ ...form, confirm_password: e.target.value })}
                                                                />
                                                                <i className={showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={toggleShowConfirmPassword}>
                                                                </i>
                                                            </div>
                                                            {errors.confirm_password && <span className="invalid-feedback d-block">{errors.confirm_password}</span>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="text-end py-3">
                                                    <Button variant="primary btn-sm px-3 text-white fs-5" onClick={handleSaveData}>
                                                       Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeTab === 'tab2' &&
                                <>
                                    <div className="row my-4">
                                        <div className="col-4 d-flex">
                                            <div className="card radius-15 bg-gray w-100 p-3 ps-4">
                                                <h5 className="header-text">EXISTING PAYMENT DETAILS</h5>
                                                <Row>
                                                    <Col md={6}>
                                                        <p className="py-1">Bank Name:</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="py-1">Name here</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="py-1">Account Holder Name:</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p>Test Card</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="py-1">Card Number:</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p>45645645412</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="py-1">Expiry Date:</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        02/28
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="py-1">Routing Number:
                                                        </p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p>656489788</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-8 d-flex">
                                            <div className="card radius-15 bg-gray w-100 p-3 ps-4">
                                                <h5 className="header-text">ADD NEW PAYMENT DETAILS</h5>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='bank_name' className="f-w600 mb-2">Bank Name</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='bank_name'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.bank_name ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.bank_name}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, bank_name: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.bank_name && <span className="invalid-feedback d-block">{errors.bank_name}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='full_name' className="f-w600 mb-2">Account Holder Name</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='full_name'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.full_name ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.full_name}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, full_name: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.full_name && <span className="invalid-feedback d-block">{errors.full_name}</span>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='card_number' className="f-w600 mb-2">Card Number</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='card_number'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.card_number ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.card_number}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, card_number: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.card_number && <span className="invalid-feedback d-block">{errors.card_number}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='exp_date' className="f-w600 mb-2">Expiry Date</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='exp_date'
                                                                    type='date'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.exp_date ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.exp_date}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, exp_date: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.exp_date && <span className="invalid-feedback d-block">{errors.exp_date}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='phone_number' className="f-w600 mb-2">Phone Number</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='phone_number'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.phone_number ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.phone_number}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, phone_number: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.phone_number && <span className="invalid-feedback d-block">{errors.phone_number}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='cvv' className="f-w600 mb-2">CVV</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='cvv'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.cvv ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.cvv}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, cvv: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.cvv && <span className="invalid-feedback d-block">{errors.cvv}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='email' className="f-w600 mb-2">Email</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='email'
                                                                    type='email'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.email ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.email}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, email: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.email && <span className="invalid-feedback d-block">{errors.email}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='address' className="f-w600 mb-2">Address</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='address'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.address ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.address}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, address: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.address && <span className="invalid-feedback d-block">{errors.address}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='city' className="f-w600 mb-2">City</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='city'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.city ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.city}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, city: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.city && <span className="invalid-feedback d-block">{errors.city}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='state' className="f-w600 mb-2">State</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='state'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.state ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.state}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, state: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.state && <span className="invalid-feedback d-block">{errors.state}</span>}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='zip' className="f-w600 mb-2">Zip</label>
                                                            <div className="align-items-center d-flex form-control justify-content-between">
                                                                <input
                                                                    id='zip'
                                                                    type='text'
                                                                    className={`custom-input-pass border-0 w-100 ${errors.zip ? 'is-invalid' : ''}`}
                                                                    value={paymentForm.zip}
                                                                    onChange={(e) => setPaymentForm({ ...paymentForm, zip: e.target.value })}
                                                                />
                                                            </div>
                                                            {errors.zip && <span className="invalid-feedback d-block">{errors.zip}</span>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="text-end py-3">
                                                    <Button variant="primary btn-sm px-4 pt-2" onClick={handleCardSaveData}>
                                                        SAVE
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default Profile;
