import {createContext, useEffect, useState} from "react";
import {forgottenPasswordData, loginReq, logOutReq, resetPasswordLinkData, resetLoginPasswordData, AddNewCustomerData, invoicePayLinkVerifyData} from "./_request";
import {getAuth, loginUser, removeAuth, setAuth as setAuthUser} from "../../../helpers/myHelpers";
import {customAlert} from "../../general/helpers";

export const AuthContext = createContext();
export const AuthContextProvider = ({children}) => {
    let [auth, setAuth] = useState(false);
    const [user, setUser] = useState(null);
    let [loading, setLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if(getAuth()!=null){
            setAuth(true)
        }

        const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];
        events.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });
        resetTimeout();
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });
            clearTimeout(timeoutId);
        };
    }, []);
    const resetTimeout = () => {
        clearTimeout(timeoutId);
        // Timeout after 4 hours
        const newTimeoutId = setTimeout(logOut, 4*60*60*1000);
        setTimeoutId(newTimeoutId);
    };
    const handleUserActivity = () => {
        resetTimeout();
    };
    const login = async (email,password) => {
        setLoading(true)
        let data = await loginReq(email,password)
        if(data.status.toLowerCase() === 'success'){
            setAuthUser(data.token);
            setUser(data.user);
            loginUser(data.user);
            setAuth(true)
            setLoading(false)
        }else{
            customAlert('Credential Not Matched!', 'error');
            setLoading(false)
        }
        setLoading(false)
    };

    const logOut = async () => {
        setLoading(true)
        let data = await logOutReq();
        removeAuth();
        setUser(null);
        setAuth(false);
        setLoading(false);
    };
    const forgottenPassword = async (email) => {
        return await forgottenPasswordData(email);
    }
    const resetPasswordLinkVerify = async (email, token) => {
        return await resetPasswordLinkData(email, token);
    }
    const resetLoginPassword = async (email, token, password) => {
        return await resetLoginPasswordData(email, token, password);
    }
    const AddNewCustomer = async (data) => {
        return await AddNewCustomerData(data);
    }
    const invoicePayLinkVerify = async (token) => {
        return await invoicePayLinkVerifyData(token);
    }
    return (
        <AuthContext.Provider value={{
            loading,
            auth,
            user,
            setUser,
            login,
            logOut,
            forgottenPassword,
            resetPasswordLinkVerify,
            resetLoginPassword,
            AddNewCustomer,
            invoicePayLinkVerify
        }}>
            {children}
        </AuthContext.Provider>
    )
}
