import {createContext, useEffect, useState} from "react"
import {getDocInfoReq,} from "./_request"
export const DocContext = createContext()
export const DocContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [docList, setDocList] = useState([])

    useEffect(() => {
        getDocInfo()
    }, [])

    const getDocInfo = async () => {
        setLoading(true)
        let data = await getDocInfoReq()
        setDocList(data.doc_list)
        setLoading(false)
    };
    return (
        <DocContext.Provider value={{
            isLoading: loading,
            loading,
            docList,
            getDocInfo,
        }}>
            {children}
        </DocContext.Provider>
    )
}
