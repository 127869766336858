import {Col} from "react-bootstrap";
import React from "react";

export const Agreement = ({userName, payable, totalApps, discount}) => {
    return (
        <Col md={12} className='mt-3'>
            <h3 className='text-center'>IMPORTANT - PLEASE READ THE TERMS BELOW THOROUGHLY</h3>
            <p>This agreement outlines the terms and conditions under which Atlantis RCM LLC will provide Credentialing Services (as defined below) to the Client, if purchased through an Order Form.</p>
            <h4 className="text-center">Agreement Terms:</h4>
            <p>The terms and conditions of the original Services Agreement, including all Exhibits and Attachments, shall continue to govern the relationship between {userName} and Atlantis RCM, unless otherwise modified in this Addendum. Any terms of the original Agreement that do not conflict with this Addendum will supplement the provisions of this Addendum for the purpose of providing Credentialing Services.</p>
            <ol>
                <li className='mb-4'>
                    <h4>Credentialing Services:</h4>
                    <p>Atlantis RCM will provide the following Credentialing Services (collectively referred to as "Credentialing Services"):</p>
                    <ul>
                        <li>
                            Submit and verify receipt of the credentialing application to designated health plans.
                        </li>
                        <li>
                            Follow up on the application with designated health plans.
                        </li>
                        <li>Document receipt of requested information from designated health plans.</li>
                        <li>Conduct follow-up activities and document acceptance or rejection information from the health plan.</li>
                        <li>
                            Provide submission of additional information requested by designated health plans during the credentialing and enrollment process, but not including submission of different health plan applications for the same provider or new applications for other providers.
                        </li>
                        <li>Atlantis RCM is not required to perform any additional services beyond the above.</li>
                    </ul>
                </li>
                <li className='mb-4'>
                    <h4>Client Responsibilities:</h4>
                    <ul>
                        <li>The Client will assist Atlantis RCM in completing a credentialing profile for each provider and, if applicable, the business entity for whom Credentialing Services are being provided. This may be done by submitting a signed copy of a standard credentialing implementation form or by providing login information for a complete CAQH profile for downloading credentialing data.</li>
                        <li>
                            The Client will submit all necessary documents and information (collectively referred to as "Credentialing Information") required to complete the credentialing application(s).
                            c. The Client agrees to provide any information or assistance needed by Atlantis RCM to fulfill its obligations under this Addendum in a timely manner. This assistance may include, but is not limited to, requests for additional information, supporting documents, and document signatures.
                        </li>
                        <li>The Client agrees to provide the Credentialing Information without delay. The Client understands that delays in providing this information may result in delays or rejection of the provider's credentialing.</li>
                        <li>
                            The Client warrants that the Credentialing Information provided is accurate and complete. The Client agrees to disclose all adverse actions from state licensing boards, health plans, healthcare facilities, government health programs, professional organizations, medical specialty boards, or any other healthcare organizations related to the provider's professional practice, starting from the time of graduation from medical school. The Client also agrees to disclose any criminal convictions related to professional practice and any additional information deemed essential for Atlantis RCM to provide Credentialing Services.
                        </li>
                    </ul>
                </li>
                <li className='mb-4'>
                    <h4>Payment Terms:</h4>
                    <p>The Client acknowledges and is liable to pay {payable}% advance of agreed-upon service fee upfront. In the event of a dispute regarding the Services, the Client may contact Atlantis RCM, and Atlantis RCM will work to resolve the issue.</p>
                    <ul>
                        <li>The client acknowledges that {userName}% payment will be due and payable at the time of contract signing</li>
                    </ul>
                </li>
                <li className='mb-4'>
                    <h4>Term & Termination:</h4>
                    <ol>
                        <li> Term. This agreement shall be effective upon the Effective Date and shall be terminated once a response to the Credentialing Information of the Client is received from the concerned health plan.</li>
                        <li>Termination by Atlantis RCM. Atlantis RCM may immediately terminate this agreement upon Client's refusal to pay fees as outlined in this agreement. Termination of services for this reason will not excuse the Client from the total amount due under this agreement if services have already been started or performed.</li>
                        <li>Effect of Termination. Upon expiration or termination of this agreement: (i) Atlantis RCM shall be entitled to receive any unpaid fees earned through the date of termination, and in the event of early termination the total full payment amounts that would have been due through the end of the term; and (ii) Atlantis RCM shall cease performing any and all Credentialing Services hereunder.</li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <h4>Miscellaneous:</h4>
                    <ul>
                        <li>Indemnification: The Client agrees to indemnify, defend, and hold harmless Atlantis RCM and its directors, officers, employees, agents, representatives, successors, assigns, subcontractors, members and affiliates from and against any and all claims, demands, actions, settlements or judgments, including reasonable attorneys’ fees and litigation expenses arising from the negligence, actions or omissions of the Client or their employees or agents; breaches of this agreement by the Client; inaccurate, incomplete or false Credentialing Information or any relevant information provided to Atlantis RCM by the Client or its practitioners; the Client's failure to provide accurate information or disclosure of all adverse actions of practitioners.</li>
                        <li>Authority: Atlantis RCM shall have the authority to conduct the Credentialing Services required by the Client, as set forth herein, subject only to the express limitations herein set forth. In fulfilling its duties and obligations hereunder, Atlantis RCM shall have all such authority, rights, and powers as are necessary, advisable or consistent herewith and the exercise of the authority conferred by this agreement on Atlantis RCM shall serve to bind the Client and shall constitute the act of the Client.</li>
                    </ul>
                    <p className='mt-3'>The Client has signed up for {totalApps} insurance applications, including a discount of ${discount}.</p>
                </li>
            </ol>
        </Col>
    )
}
