import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import './loginForm.css'
import {AuthContext} from "./context/AuthContext";
import {customAlert} from "../general/helpers";
import {RegisterCustomerModal} from "./modal/RegisterCustomerModal";
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = () => {
    const {state} = useLocation();
    const {login, forgottenPassword} = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyEmail, setVerifyEmail] = useState('');
    const [isForgetPassword, setIsForgetPassword] = useState(false);
    const [error, setError] = useState({});
    const [errorForgetPassword, setErrorForgetPassword] = useState('');
    const [isVerified,setIsVerified ] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [registeredCustomer, setRegisteredCustomer] = useState(false);
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const validationErrors = {};
        if(!email) {
            validationErrors.email = 'Email is Required!';
        }else {
            if (!isEmailPatternValid(email)) {
                validationErrors.email = 'Invalid Email Format';
            }
        }
        if(!password) {
            validationErrors.password = 'Password is Required!';
        }
        if(!isCaptchaVerified) {
            validationErrors.captcha = 'Captcha is Required!';
        }
        if (Object.keys(validationErrors).length === 0) {
            await login(email,password)
            setError({});
        }else {
            setError(validationErrors);
        }
        setLoading(false);
    };
    const handleForgottenPassword = (e) => {
        e.preventDefault();
        setIsVerified(true);
        if(verifyEmail) {
            if (isEmailPatternValid(verifyEmail)) {
                forgottenPassword(verifyEmail).then(res => {
                    if (res.status.toLowerCase() === 'success') {
                        customAlert(res.result, 'success');
                        setErrorForgetPassword('');
                        setVerifyEmail('');
                        setIsVerified(false);
                        setIsForgetPassword(false);
                    }else{
                        setErrorForgetPassword(res.result);
                        setIsVerified(false);
                    }

                });
            } else {
                setErrorForgetPassword('Invalid Email Format');
                setIsVerified(false);
            }
        }else {
            setErrorForgetPassword('Enter Your Email');
            setIsVerified(false);
        }

    }
    const handleCaptchaChange = (response) => {
        if (response) {
            setIsCaptchaVerified(true);
            //console.log("Verified successfully:");
        } else {
            console.error("Verification failed!");
        }
    };
    const isEmailPatternValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
        return emailRegex.test(email);
    };
    useEffect(() => {
        if(state?.data) {
            setShowAlert(true);
            const timeout = setTimeout(() => {
                setShowAlert(false);
            }, 15000);
            return () => clearTimeout(timeout);
        }

    }, [state?.data]);
    return (
        <div className='container-fluid vh-100'>
            <div className='vh-100'>
                <div className='row'>
                    <div className='col-md-6 order-1 order-md-0 d-none d-md-block'>
                        {showAlert && (
                            <div className="alert alert-danger alert-dismissible fade show d-inline-block w-100 text-center" role="alert">
                                {state?.data}
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)}></button>
                            </div>
                        )}
                        <div className="d-flex justify-content-center align-items-center h-100 login-form-sec">
                            <form className='form w-75' onSubmit={handleLogin}
                                  noValidate id='kt_login_signin_form'>
                                {/* begin::Heading */}
                                <div className='text-center mb-11'>
                                    <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                                </div>
                                <div className='fv-row mb-8'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                                    <input
                                        placeholder='Email'
                                        className={`form-control bg-transparent ${error?.email ? 'is-invalid' : ''} `}
                                        type='email'
                                        name='email'
                                        value={email}
                                        required
                                        onChange={ (e) => {setEmail(e.target.value)}}
                                        autoComplete='off'
                                    />
                                    {error?.email && <span className="invalid-feedback fw-bold">{error?.email}</span>}
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        autoComplete='off'
                                        value={password}
                                        required
                                        onChange={ (e) => {setPassword(e.target.value)}}
                                        className={`form-control bg-transparent ${error?.password ? 'is-invalid' : ''} `}
                                    />
                                    {error?.password && <span className="invalid-feedback fw-bold">{error?.password}</span>}
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <div className={`${error?.captcha ? 'is-invalid' : ''}`}>
                                        <ReCAPTCHA
                                            sitekey="6LfuNqApAAAAABGz_949SdMD8p4GhBlgwc6rUUWx"
                                            onChange={handleCaptchaChange}
                                            style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}
                                        />
                                    </div>
                                    {error?.captcha && <span className="invalid-feedback fw-bold">{error?.captcha}</span>}
                                </div>
                                <div className='d-grid mb-10'>
                                    <button disabled={loading} type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                                        <span className='indicator-label'>
                                            {loading?'Loading...':'Login'}</span>
                                    </button>
                                </div>
                                {/* end::Action */}
                                <div className='d-grid mb-10'>
                                    <div className="d-flex justify-content-between text-center">
                                        <button type='button' className='btn bg-transparent border-0 fs-6 mt-3 text-primary' onClick={ () => setRegisteredCustomer(!registeredCustomer) }>Create a free account</button>
                                        <button type='button' className='btn bg-transparent border-0 fs-6 mt-3 text-primary' onClick={ () => setIsForgetPassword(!isForgetPassword) }>Forgotten password?</button>
                                    </div>
                                    {isForgetPassword && (
                                        <div className="input-group mb-5 mt-2">
                                            <input type="email" className={`form-control bg-transparent verifyEmail ${errorForgetPassword ? 'is-invalid' : ''} `} placeholder="Enter your email" value={verifyEmail} onChange={ (e) => setVerifyEmail(e.target.value)} />
                                            <button onClick={handleForgottenPassword} className="btn btn-sm btn-primary px-4 fs-2 py-0 h-30px" title="Send" type="button"><i className={`fas ${isVerified ? 'fa-spinner fa-spin' : 'fa-send'} fs-4`}></i></button>
                                            {errorForgetPassword && <span className="invalid-feedback fw-bold">{errorForgetPassword}</span>}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div
                        className='col-md-6 text-center vh-100 text-sec-login order-0 order-md-1' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/login-page-bg.webp'})`}}>
                        <div className='d-flex flex-column loginFormPg h-100 justify-content-center flex-center py-15 px-5 px-md-15 w-100'>
                            <Link to='/' className='mb-12'>
                                <img alt='Logo' src={process.env.PUBLIC_URL + '/img/logo.png'} style={{ height: 'auto' }} className='h-75px mb-3 mobileLogoLogin'/>
                            </Link>
                            <h1 className='text-white fs-2qx fw-bolder text-center my-3 d-none d-md-block'>
                                Welcome to the Atlantis RCM Provider Portal
                            </h1>
                            <div className='text-white text-center d-none d-md-block'>
                                <p>Experience the future of credentialing! Track real-time updates <br /> and let our automated system streamline
                                    your <br />credentialing journey.</p>
                            </div>
                            <form className='form w-100 d-block d-md-none bg-gray py-5 px-4' onSubmit={handleLogin}
                                  noValidate id='kt_login_signin_form_1'>
                                {/* begin::Heading */}
                                <div className='text-center mb-11'>
                                    <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                                </div>
                                <div className='fv-row mb-8'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                                    <input
                                        placeholder='Email'
                                        className={`form-control bg-transparent ${error?.email ? 'is-invalid' : ''} `}
                                        type='email'
                                        name='email'
                                        value={email}
                                        required
                                        onChange={ (e) => {setEmail(e.target.value)}}
                                        autoComplete='off'
                                    />
                                    {error?.email && <span className="invalid-feedback fw-bold">{error?.email}</span>}
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        autoComplete='off'
                                        required
                                        value={password}
                                        onChange={ (e) => {setPassword(e.target.value)}}
                                        className={`form-control bg-transparent ${error?.password ? 'is-invalid' : ''} `}
                                    />
                                    {error?.password && <span className="invalid-feedback fw-bold">{error?.password}</span>}
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <div className={`d-flex justify-content-center ${error?.captcha ? 'is-invalid' : ''}`}>
                                        <ReCAPTCHA
                                            sitekey="6LfuNqApAAAAABGz_949SdMD8p4GhBlgwc6rUUWx"
                                            onChange={handleCaptchaChange}
                                            style={{ transform: 'scale(0.7)', transformOrigin: '0 0' }}
                                        />
                                    </div>
                                    {error?.captcha && <span className="invalid-feedback fw-bold">{error?.captcha}</span>}
                                </div>
                                <div className='d-grid mb-10'>
                                    <button disabled={loading} type='submit' id='kt_sign_in_submit_1' className='btn btn-primary'>
                                        <span className='indicator-label'>
                                            {loading?'Loading...':'Login'}</span>
                                    </button>
                                </div>
                                {/* end::Action */}
                                <div className='d-grid mb-10'>
                                    <div className="d-flex justify-content-between text-center">
                                        <button type='button' className='btn bg-transparent border-0 fs-7 ps-0 mt-3 text-primary' onClick={ () => setRegisteredCustomer(!registeredCustomer) }>Create a free account</button>
                                        <button type='button' className='btn bg-transparent border-0 fs-7 pe-0 mt-3 text-primary' onClick={ () => setIsForgetPassword(!isForgetPassword) }>Forgotten password?</button>
                                    </div>
                                    {isForgetPassword && (
                                        <div className="input-group mb-5 mt-2">
                                            <input type="email" className={`form-control bg-transparent ${errorForgetPassword ? 'is-invalid' : ''} `} placeholder="Enter your email" value={verifyEmail} onChange={ (e) => setVerifyEmail(e.target.value)} />
                                            <button onClick={handleForgottenPassword} className="btn btn-sm btn-primary px-4 fs-2 py-0 h-30px" title="Send" type="button"><i className={`fas ${isVerified ? 'fa-spinner fa-spin' : 'fa-send'} fs-4`}></i></button>
                                            {errorForgetPassword && <span className="invalid-feedback fw-bold">{errorForgetPassword}</span>}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {registeredCustomer && <RegisterCustomerModal show={registeredCustomer} close={ () => setRegisteredCustomer(false)} />}
        </div>
    );
};

export default LoginForm;