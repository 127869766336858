// Format is showing Date only like 2 Nov 2023 11:30 AM
export const formatDateDMY = (dateString) => {
    if (!dateString) {
        return '';
    }
    const parsedDate = new Date(dateString);
    const monthAbbreviations = [
        'Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    const day = parsedDate.getDate();
    const monthAbbreviation = monthAbbreviations[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    return `${day}-${monthAbbreviation}-${year}`;
}

// with full Month name
export const formatDateDMYFullMonth = (dateString) => {
    if(!dateString) {
        return '';
    }
    const parsedDate = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return parsedDate.toLocaleDateString('en-GB', options);
}
// Format is showing Date only like 2 Nov 2023
export const formatDateDMYTime = (dateTimeString) => {
    const datetime = new Date(dateTimeString);
    return datetime.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit' });
}

export const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString();
}

export const getUSADateAndTime = () => {
    const now = new Date();
    return now.toLocaleString('en-US');
}
// Format current date and add days to it
export const addDaysToDate = (dateString, daysToAdd) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date;
}
// Format is X minutes ago and After 24 hrs showing Date and Time
export const formatRelativeTimeAndDate = (dateTimeString) => {
    const entryTime = new Date(dateTimeString);
    const currentTime = new Date();
    const timeDifferenceInSeconds = Math.floor((currentTime - entryTime) / 1000);

    if (timeDifferenceInSeconds < 60) {
        return `${timeDifferenceInSeconds} seconds ago`;
    } else if (timeDifferenceInSeconds < 3600) {
        const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutesAgo} minutes ago`;
    } else if (timeDifferenceInSeconds < 86400) {
        const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hoursAgo} hours ago`;
    } else {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return entryTime.toLocaleString('en-GB', options);
    }
}
// Format is X minutes ago and After 24 hrs showing Date only
export const formatRelativeOnlyDate = (dateString) => {
    const entryTime = new Date(dateString);
    const currentTime = new Date();
    const timeDifferenceInSeconds = Math.floor((currentTime - entryTime) / 1000);

    if (timeDifferenceInSeconds < 60) {
        return `${timeDifferenceInSeconds} seconds ago`;
    } else if (timeDifferenceInSeconds < 3600) {
        const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutesAgo} minutes ago`;
    } else if (timeDifferenceInSeconds < 86400) {
        const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hoursAgo} hours ago`;
    } else {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return entryTime.toLocaleString('en-GB', options);
    }
}
// Format is X minutes ago
export const formatTimeAgo = (dateTimeString) => {
    const entryTime = new Date(dateTimeString);
    const currentTime = new Date();
    const timeDifferenceInSeconds = Math.floor((currentTime - entryTime) / 1000);

    if (timeDifferenceInSeconds < 60) {
        return `${timeDifferenceInSeconds} seconds ago`;
    } else if (timeDifferenceInSeconds < 3600) {
        const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
        const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (timeDifferenceInSeconds < 604800) { // 7 days
        const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
        return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    } else {
        const weeksAgo = Math.floor(timeDifferenceInSeconds / 604800); // 7 days * 24 hours * 60 minutes * 60 seconds
        return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
    }
}

// Today showing Time, after 24 hrs showing Yesterday, and after 2 days showing Date
export const todayTimeYesterdayDate = (dateTimeString) => {
    if(!dateTimeString) {
        return '';
    }
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0
    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    const currentDate = dd + '/' + mm + '/' + yyyy;

    const inputDate = new Date(dateTimeString);
    const inputDateString = inputDate.toDateString();

    if (inputDateString === today.toDateString()) {
        const time = inputDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return 'Today ' + time;
    } else if (inputDateString === new Date(today.setDate(today.getDate() - 1)).toDateString()) {
        return 'Yesterday';
    } else {
        const inputDay = inputDate.getDate();
        const inputMonth = inputDate.getMonth() + 1;
        const inputYear = inputDate.getFullYear() % 100; // Assuming you want a two-digit year

        return `${inputDay}/${inputMonth}/${inputYear}`;
    }
};

export const daysOfWeek = (dateString) => {
    const date = new Date(dateString);
    const days = date.toLocaleDateString('en-US', { weekday: 'short' });
    return days;
}

//Limit the number of words
export const truncateWordsLimit = (contentFilter, wordLimit) => {
    const limit = wordLimit || 4;
    const words = contentFilter.split(' ');
    const limitContent = words.slice(0, limit).join(' ');
    return limitContent.length < contentFilter.length ? limitContent + ' ...' : limitContent;
};
//Limit the number of characters
export const truncateCharacterLimit = (contentFilter, characterLimit, truncateSymbol) => {
    const limit = characterLimit || 20;
    if (contentFilter.length <= limit) {
        return contentFilter;
    } else {
        const truncatedContent = contentFilter.slice(0, limit);
        const truncatedSymbol = truncateSymbol ? '...' : ' ';
        return truncatedContent + truncatedSymbol;
    }
};

//Two parameters, pathname and number of level
export const splitIdFromPathName = (pathname, defLevel) => {
    const parts = pathname.split('/');
    const id = parts[parts.length - defLevel];
    const numericId = parseInt(id, 10);
    return numericId;
};

//equal height
export const setEqualHeight = (customClass) => {
    const equalHeightClass = customClass || '.equalHeightColumn';
    const columns = document.querySelectorAll(equalHeightClass);
    let maxHeight = 0;
    columns.forEach(column => {
        column.style.height = 'auto';
        const columnHeight = column.offsetHeight;
        maxHeight = Math.max(maxHeight, columnHeight);
    });
    columns.forEach(column => {
        column.style.height = `${maxHeight}px`;
    });
}

//toAbsoluteUrl
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const bgImgFunc = (bgPath) => {
    return new URL(bgPath, window.location.origin).toString();
}