import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_USER_INO_URL = REACT_APP_BACKEND_API_URL+"user"
const RESET_PASSWORD_URL = REACT_APP_BACKEND_API_URL+"reset_password"
const ADD_CARD_DETAIL_URL = REACT_APP_BACKEND_API_URL+"add_card_detail"
let getUserInfoReq = async () => {
    try {
        const d = await axios
            .get(`${GET_USER_INO_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in Login Req() : ", error);
        throw error;
    }
}
let resetPasswordReq = async (data) => {
    try {
        const d = await axios
            .post(`${RESET_PASSWORD_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in Login Req() : ", error);
        throw error;
    }
}
let addCardDetailReq = async (data) => {
    try {
        const d = await axios
            .post(`${ADD_CARD_DETAIL_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in Login Req() : ", error);
        throw error;
    }
}
export {
    getUserInfoReq,
    resetPasswordReq,
    addCardDetailReq,
}