import React, {useContext, useEffect, useState} from 'react';
import {useLocation, Link} from "react-router-dom";
import {PersonalInfo} from "./components/PersonalInfo";
import {UploadDocument} from "./components/UploadDocument";
import {UserContext, UserContextProvider} from "./context/UserContext";
import {UploadDocumentContextPrvider} from "./context/UploadDocumentContext";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {HeaderTitle} from "../general/HeaderTitle";
import {bgImgFunc} from "../general/utils";
import {Col, Row} from "react-bootstrap";
import {customAlert} from "../general/helpers";
import {KTSVG} from "../general/KTSVG";
const UserDetailsForm = () => {
    const {getPractices, addPractices, userData, addInsurancesWithCredential, getSelectedInsurances, addInsurances} = useContext(UserContext)
    const location = useLocation()
    const [formType, setFormType] = useState('Provider');
    const [avblPractices, setAvblPractices] = useState([])
    const [practices, setPractices] = useState([])
    const [info, setInfo] = useState([])
    const [documents, setDocuments] = useState({})
    const [logins, setLogins] = useState({})
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedInsurance, setSelectedInsurance] = useState([]);
    const [showSelectedInsurances, setShowSelectedInsurances] = useState([]);
    const [showAddPracticeBtn, setShowAddPracticeBtn] = useState(false);
    const [showAddInsuranceBtn, setShowAddInsuranceBtn] = useState(false);
    const [insuranceLoading, setInsuranceLoading] = useState(false);

    const animatedComponents = makeAnimated();
    let credential_id = location?.state?.item?.credential_id;
    let user_id = location?.state?.item?.user_id;
    useEffect( () => {
        // console.log('check record here ', location.state)
        const fetchData = async () => {
            if (location.state.item.form_type === 'credentialing_agencies') {
                setFormType('Practice');
                setLogins({ credential_id: location.state.item.credential_id });
                setDocuments({ credential_id: location.state.item.credential_id });
            } else {
                await getPractic();
            }
            if(credential_id && user_id){
                await callSelectedInsurances();
            }

            if (location.state.userData) {
                setAvblPractices(location.state.userData.practices);
            }

            setInfo(location.state.item);

            if (location.state.item.credential_documents) {
                setDocuments(location.state.item.credential_documents);
            }

            if (location.state.item.logins) {
                setLogins(location.state.item.logins);
            }
        };
        fetchData();
    }, [location.state.item]);

    const getPractic = async () =>{
        let practice =  await getPractices(location.state.item.credential_id)
        setPractices(practice.practices)
        setAvblPractices(practice.available_practices)
        setSelectedOptions([]);
    }
    const callSelectedInsurances = async () => {
        setInsuranceLoading(true);
        const selectedPractices = await getSelectedInsurances(credential_id, user_id);
        if(selectedPractices?.status === 'success'){
            setShowSelectedInsurances(selectedPractices?.selected_insurances);
            setSelectedOptions([]);
            setInsuranceLoading(false);
        }else{
            console.error("Error in update callSelectedInsurances() :", selectedPractices?.result);
            setInsuranceLoading(false);
        }
        setInsuranceLoading(false);
    }
    const handleMultiSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        if(selectedOptions.length>0){
            setShowAddPracticeBtn(true)
        }else{
            setShowAddPracticeBtn(false)
        }
    };
    const addProviderPractices = async () => {
        let selectedPractices = selectedOptions.map((name) => (
            name.value
        ));
        let formData = new FormData();
        formData.append('credential_id', info.credential_id);
        formData.append('selected_options', selectedPractices);
        await addPractices(formData);
        await getPractic();
        setShowAddPracticeBtn(false);
    }
    const handleMultiSelectInsurance = (selectedOptions) => {
        setSelectedInsurance(selectedOptions);
        if(selectedOptions.length>0){
            setShowAddInsuranceBtn(true);
        }else {
            setShowAddInsuranceBtn(false);
        }
    }
    const addInsuranceBoth = async () => {
        let selectedInsuranceList = selectedInsurance.map((name) => (
            name.value
        ));
        let formData = new FormData();
        formData.append('insurance_ids', selectedInsuranceList);
        formData.append('user_id', user_id);
        formData.append('credential_id', credential_id);
        //add insurances or Payers
        formData.append('insurances', selectedInsuranceList)
        if(credential_id && user_id) {
            //add insurances or Payers
            await addInsurances(formData)
            //add selected insurances
            const response = await addInsurancesWithCredential(formData);
            if(response?.status.toLowerCase() === 'success'){
                if(response?.selected_insurances_lists.length>0){
                    customAlert(response.result, 'success');
                    await callSelectedInsurances();
                }else{
                    customAlert('Insurance already exist', 'error');
                }

            }
            setSelectedInsurance([]);
            setShowAddInsuranceBtn(false);
        }else{
            customAlert(`Please fill ${formType} information first`, 'error');
        }
    }
    const pageTitle = () => {
        if (location.state.item.form_type === 'credentialing_agencies') {
            return `${location.state.item.group_name ? location.state.item.group_name : 'ADD PRACTICE'}`;
        }
        return `${location.state.item.provider_name ? location.state?.item?.provider_name : 'ADD PROVIDER'}`;
    };

    return (
        <div className="container-fluid">
            <div className="row mx-0">
                <div className="col-12 bg-gray radius-15">
                    <Link to={{ pathname: '/user-details' }} ></Link>
                    <HeaderTitle title={pageTitle()} dispatchBy="userDetail"/>
                </div>
            </div>

            <>
                <div className="row my-4">
                    <div className="col-12 col-md-7 d-flex">
                        <div className="card border-none border-0">
                            <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center" >
                                <h5 className="header-text text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">{formType} Details</h5>
                                {/*<button type="button"  className="btn"><span className="text-primary f-w500"><KTSVG path="/svg/downarrow.svg" /> </span></button>*/}
                            </div>
                            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                                <UserContextProvider>
                                    <PersonalInfo userInfo={info} logins={logins} type={formType} onSavePractices={addProviderPractices} isPracticesAdd={showAddPracticeBtn}/>
                                </UserContextProvider>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 mt-4 mt-md-0">
                        {formType === 'Provider' && info.credential_id ? (
                            <div className="card border-0 mb-4" style={{ height: 'auto'}}>
                                <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center" >
                                    <h5 className="header-text text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">CHOOSE PRACTICE</h5>
                                </div>
                                <div className="card-body pb-3 rounded-bottom-4 bgGray border-top-0 border-top-right-0 border-top-left-0">
                                    {/*show user practices here*/}
                                    <div className="row">
                                        <div className="col-12">
                                            <Select
                                                isMulti
                                                className="my-3 p-0 h-30px"
                                                components={animatedComponents}
                                                placeholder="Select Practice"
                                                value={selectedOptions}
                                                onChange={handleMultiSelectChange}
                                                options={avblPractices && avblPractices.map((list) => ({
                                                    value: list?.credential_id,
                                                    label: list?.group_name,
                                                }))}
                                            />
                                            <button onClick={addProviderPractices} className={`${showAddPracticeBtn?'d-block':'d-none'} btn btn-primary btn-sm mb-2 float-end fw-bold rounded-1`}>Add Practice</button>
                                            <div className="mb-2">
                                                {practices && practices.map((item, index) =>
                                                        item?.practice?.group_name && (
                                                            <>
                                                                <button key={index} className="btn bg-white practiceBtn borderGray me-2 mt-2">{item?.practice?.group_name}</button>
                                                                {showSelectedInsurances.some(insurance => insurance?.practice_id === item?.practice_id) && (<span className="btn btn-icon btn-circle btn-color-muted btn-active-color-danger bg-body shadow insuranceRemove" title="remove"><i className="fa fa-times text-danger fs-6 removeIcon"></i></span>)}

                                                            </>
                                                        )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        <div className="card border-0 mb-4" style={{ height: 'auto'}}>
                            {insuranceLoading  && (
                                <div className="loadingOverLay">
                                    <h4>Insurances is Loading. Please Wait</h4>
                                </div>
                            )}
                            <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-9 headerBgImg text-uppercase align-middle align-items-center" >
                                <h5 className="header-text text-white  pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">CHOOSE Payers</h5>
                            </div>
                            <div className="card-body pb-3 rounded-bottom-4 bgGray border-top-0 border-top-right-0 border-top-left-0">
                                <div className="row">
                                    <div className="col-12">
                                        <Select
                                            isMulti
                                            className="my-3 p-0 h-30px"
                                            components={animatedComponents}
                                            placeholder="Select Insurance"
                                            value={selectedInsurance}
                                            onChange={handleMultiSelectInsurance}
                                            options={userData && userData?.insurance_list
                                                .filter((list) =>
                                                    !showSelectedInsurances.some(selected => selected.insurance_id === list.id)
                                                )
                                                .map((list) => ({
                                                    value: list?.id,
                                                    label: list?.title,
                                                }))}
                                        />
                                        { (credential_id === undefined || credential_id === null) && <span className="text-danger me-3">Please fill the required fields for {formType} Details </span> }
                                        <button onClick={addInsuranceBoth} className={`${showAddInsuranceBtn ? 'd-block' : 'd-none'} btn btn-primary btn-sm mb-2 float-end fw-bold rounded-1`} disabled={!credential_id && true}>Add Insurance</button>
                                        <div className="mb-2">
                                            {showSelectedInsurances && showSelectedInsurances.map((list, index) =>
                                                    list?.insurance?.title && (
                                                        <button key={index} className="btn bg-white practiceBtn borderGray me-2 mt-2">{ list?.insurance?.title}</button>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 d-flex" style={{ height: 'auto'}}>
                            <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center" >
                                <h5 className="header-text text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">Upload Documents</h5>
                            </div>
                            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                                <UserContextProvider>
                                    <UploadDocumentContextPrvider>
                                        <UploadDocument userInfo={info} data={documents} formType={formType} />
                                    </UploadDocumentContextPrvider>
                                </UserContextProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default UserDetailsForm;
