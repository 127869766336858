export const NotFound = ({message}) => {
    return (
        <>
            <div className="container mt-8">
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                    <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </i>
                    <div className="d-flex flex-stack flex-grow-1 ">
                        <div className=" fw-semibold">
                            <h4 className="text-gray-900 fw-bold">{message || "N/A"}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
