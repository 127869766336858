import React, { createContext, useState } from 'react';
import {updateDocumentReq, removeCustomerDocsData} from "./_request";

export const UploadDocumentContext = createContext();

export const UploadDocumentContextPrvider = ({ children }) => {
    const updateDocument = async (data) => {
        let resdata = await updateDocumentReq(data)
        return resdata;
    }

    const contextValue = {
        updateDocument,
    };
    const removeCustomerDocs = async (data, user_id) => {
        return await removeCustomerDocsData(data);
    }

    return (
        <UploadDocumentContext.Provider value={{contextValue, updateDocument, removeCustomerDocs}}>
            {children}
        </UploadDocumentContext.Provider>
    );
};
